import React from 'react';
import { StyledErrorFallback } from './ErrorBoundary.styles';
import text from '../../global/text/text.json';

interface ErrorFallbackProps {
  error: Error;
  secondaryMessage?: string;
  handleErrorBoundaryReset?: () => void;
  resetButtonText?: string;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  secondaryMessage = text.errorBoundaryText,
  resetButtonText = text.errorBoundaryButtonText,
  handleErrorBoundaryReset,
}) => (
  <StyledErrorFallback role="alert">
    <h1>{text.errorBoundaryTitle}</h1>
    <p className="error-message">{error.message}</p>
    <p>{secondaryMessage}</p>
    {handleErrorBoundaryReset && (
      <button data-testid="button-reset-app-state" onClick={handleErrorBoundaryReset}>
        {resetButtonText}
      </button>
    )}
  </StyledErrorFallback>
);

export default ErrorFallback;
