import logger from '../../Common/global/logger';
import { createFullPath, getPathSeparator } from '../../Common/global/path';
import text from '../../Common/global/text/text.json';
import { HostApi, Result } from '../interfaces/cefSharp';
import {
  BooleanInput,
  DynamicContentInput,
  DynamicContentProduct,
  IPropertyInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
  TemplateContext,
  TemplateInputType,
  TemplateOutput,
  TemplateRule,
  TextInput,
} from '../interfaces/dynamicContent';
import {
  InventorParameter,
  IProperty,
  isBooleanInventorParameter,
  isMultivaluedInventorParameter,
  isTextInventorParameter,
} from '../interfaces/inventorProperties';
import {
  DraftTemplate,
  DraftTemplateBooleanInput,
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  DraftTemplateMultiValueNumericInput,
  DraftTemplateMultiValueTextInput,
  DraftTemplateNumericInput,
  DraftTemplateOutput,
  DraftTemplatePublishResult,
  DraftTemplateTextInput,
  InputRule,
  MetaInfo,
  MetaInfoPath,
  OutputType,
  SerializedBlocklyWorkspaceState,
} from '../interfaces/templates';
import { getUUID } from '../services/uuid.service';
import { publishProductFromDraft } from './publish';
import { getProjectFolders } from './workspace';
import { ProjectFolder } from '../interfaces/forge-api';

declare let hostApi: HostApi;

export const getDrafts = async (): Promise<DraftTemplate[]> => {
  const loadedDrafts: Result<string> = await hostApi.loadDrafts();
  if (loadedDrafts.value === null) {
    throw new Error(`${loadedDrafts.errorMessage}`);
  }

  // if we get null back, then there are no drafts yet, so we return an empty array
  const drafts: DraftTemplate[] = loadedDrafts.value.length ? JSON.parse(loadedDrafts.value) : [];
  return drafts;
};

export const saveDraft = async (draft: DraftTemplate): Promise<DraftTemplate> => {
  // read drafts from storage
  const drafts: DraftTemplate[] = await getDrafts();

  // Update lastUpdate time
  const updatedDraft: DraftTemplate = { ...draft, lastUpdated: Date.now() };
  // if draft doesn't have an id, it's a new draft,
  // so we'll need to generate one and add the new draft to the list of drafts
  if (!updatedDraft.id) {
    const draftId: string = getUUID();
    updatedDraft.id = draftId;
    drafts.push(updatedDraft);
  } else {
    const index = drafts.findIndex(({ id }) => id === draft.id);
    drafts[index] = draft;
  }

  const result: Result<boolean> = await hostApi.saveDrafts(JSON.stringify(drafts));

  // throw error if drafts couldn't be saved
  if (!result.value) {
    throw new Error(`Error while saving drafts. Error message - ${result.errorMessage}`);
  }

  return updatedDraft;
};

export const deleteDrafts = async (draftIds: string[]): Promise<DraftTemplate[]> => {
  const drafts: DraftTemplate[] = await getDrafts();

  const restDrafts = drafts.filter((draft) => !!draft.id && !draftIds.includes(draft.id));

  const result: Result<boolean> = await hostApi.saveDrafts(JSON.stringify(restDrafts));

  if (!result.value) {
    throw new Error(`Error while deleting drafts. Error message - ${result.errorMessage}`);
  }

  return restDrafts;
};

export const getThumbnailImgPath = async (iamPath: string): Promise<string | undefined> => {
  const result = await hostApi.getThumbnailImage(iamPath);
  if (result.value === null) {
    throw new Error(`${text.notificationThumbnailFailed}. Message: ${result.errorMessage}`);
  }
  return result.value;
};

export const getTopLevelAssemblyPath = (
  draftTopLevelFolder: string,
  draftAssembly: string,
): string => {
  // The  DA4I plugin expects the assembly path to include the top-folder name,
  // e.g. "Wall w Door\\Wall w Door.iam".
  const topLevelFolderPath = draftTopLevelFolder.replace(/\//g, '\\');
  const datasetFolderName = topLevelFolderPath.substring(topLevelFolderPath.lastIndexOf('\\') + 1);

  // Rely on the fact that the draft's assembly path begins with a directory separator.
  return `${datasetFolderName}${draftAssembly.replace(/\//g, '\\')}`;
};

const dcContextToDraftSourceContent = (
  productContext: TemplateContext,
): { topLevelFolder: string; inventorProject: string; assembly: string } => {
  const assembly = productContext.topLevelAssembly.substring(
    productContext.topLevelAssembly.indexOf(getPathSeparator(productContext.topLevelAssembly)),
    productContext.topLevelAssembly.length,
  );
  const topLevelFolder = productContext.topLevelAssembly.substring(
    0,
    productContext.topLevelAssembly.indexOf(getPathSeparator(productContext.topLevelAssembly)),
  );
  return { topLevelFolder, inventorProject: productContext.projectFile, assembly };
};

const validateProductOutputType = (output: TemplateOutput) =>
  Object.values(OutputType).some((value) => value.toUpperCase() === output.type.toUpperCase());

// TODO: Temporary solution until we have API calls to properly reconstruct folder path
const createDraftFolderPathFromProduct = async (
  product: DynamicContentProduct,
): Promise<MetaInfoPath> => {
  const productFolderUrns = product.context.workspace.folderPath.split('/');
  const productFolders: MetaInfo[] = [];
  const promiseToRetrieveAllProjectFolders: Promise<ProjectFolder[]>[] = [];
  for (let i = 0; i < productFolderUrns.length; i++) {
    if (i === 0) {
      // Initially, we want to retrieve all folders, not subfolders
      // So we don't pass a urn
      promiseToRetrieveAllProjectFolders.push(getProjectFolders(product.tenancyId));
    } else {
      // Moving on to subfolders
      promiseToRetrieveAllProjectFolders.push(
        getProjectFolders(product.tenancyId, productFolderUrns[i - 1]),
      );
    }
  }

  // Handling all promises at once
  const allProjectFolders: ProjectFolder[] = (
    await Promise.all(promiseToRetrieveAllProjectFolders)
  ).reduce((acc, next) => acc.concat(next), []);

  // Extracting only the URNs we need
  productFolderUrns.forEach((urn) => {
    allProjectFolders.some((projectFolder) => {
      if (projectFolder.urn === urn) {
        // Pushing folders to start of the array to create the parentPath below
        productFolders.unshift({ id: projectFolder.urn, name: projectFolder.title });
      }
    });
  });

  // Creating MetaInfoPath object
  const folderPublishLocation: MetaInfoPath = {
    id: productFolders[0].id,
    name: productFolders[0].name,
    parentPath: Array.from(
      productFolders.slice(1), // removing folder product was published in
      (folder) => ({ id: folder.id, name: folder.name } as MetaInfo),
    ),
  };

  return folderPublishLocation;
};

export const productTemplateToDraftTemplate = async (
  accountInfo: MetaInfo | undefined,
  projectInfo: MetaInfo | undefined,
  selectedDownloadLocation: string,
  product: DynamicContentProduct,
): Promise<DraftTemplate> => {
  // SOURCE CONTENT
  const { topLevelFolder, inventorProject, assembly } = dcContextToDraftSourceContent(
    product.context,
  );

  // THUMBNAIL
  const thumbnail = await getThumbnailImgPath(
    createFullPath(
      `${selectedDownloadLocation}${getPathSeparator(selectedDownloadLocation)}${topLevelFolder}`,
      assembly,
    ),
  );

  // INPUTS
  const parameters: DraftTemplateInputParameter[] = [];
  const iProperties: DraftTemplateIProperty[] = [];
  product.inputs.forEach((input) => {
    if (input.type === TemplateInputType.IProperty) {
      iProperties.push(dcInputToDraftTemplateIProperty(input));
    } else {
      parameters.push(dcInputToDraftTemplateParameter(input));
    }
  });

  // RULES
  const { rules: productRules } = product;
  const rules: InputRule[] = Object.keys(productRules).map((rule) => ({
    key: rule,
    code: productRules[rule].code,
    errorMessage: productRules[rule].errorMessage,
    label: productRules[rule].ruleLabel,
  }));

  // CODE BLOCK WORKSPACE
  const codeBlocksWorkspace: SerializedBlocklyWorkspaceState = JSON.parse(
    product.codeBlocksWorkspace,
  );

  // OUTPUTS
  const outputs: DraftTemplateOutput[] = product.outputs.map((output) => {
    if (validateProductOutputType(output)) {
      return {
        type: output.type.toUpperCase() as OutputType,
        options: output.options,
      };
    }
    logger.error(`Error: ${text.invalidOutputTypeOnProduct} ${output}`);
    throw new Error(`${text.invalidOutputTypeOnProduct} ${output}`);
  });

  // PUBLISH LOCATION
  const accountPublishLocation: MetaInfo = {
    id: accountInfo?.id || '',
    name: accountInfo?.name || '',
  };
  const projectPublishLocation: MetaInfo = {
    id: projectInfo?.id || '',
    name: projectInfo?.name || '',
  };
  // Extracting and building folder path from project
  const folderPublishLocation = await createDraftFolderPathFromProduct(product);

  // Create the draft
  const newDraftFromProduct: DraftTemplate = {
    id: '', // Generated automatically when you save draft
    name: product.name,

    // Source Content and thumbnail
    topLevelFolder: `${selectedDownloadLocation}${getPathSeparator(
      selectedDownloadLocation,
    )}${topLevelFolder}`,
    inventorProject,
    assembly,
    thumbnail: thumbnail || '',

    // Inputs, rules, and outputs
    parameters,
    iProperties,
    rules,
    codeBlocksWorkspace,
    outputs,
    table: undefined,

    // Publish location
    account: accountPublishLocation,
    project: projectPublishLocation,
    folder: folderPublishLocation,

    lastUpdated: 0,
  };

  return newDraftFromProduct;
};

export const dcInputToDraftTemplateIProperty = (input: IPropertyInput): DraftTemplateIProperty => {
  const { label, name, readOnly, value, visible, category } = input;
  const iPropertyInput: DraftTemplateIProperty = {
    type: TemplateInputType.IProperty,
    id: name,
    value,
    category,
    label,
    name,
    readOnly,
    visible,
  };
  return iPropertyInput;
};

export const dcInputToDraftTemplateParameter = (
  input: Exclude<DynamicContentInput, IPropertyInput>,
):
  | DraftTemplateBooleanInput
  | DraftTemplateTextInput
  | DraftTemplateMultiValueTextInput
  | DraftTemplateNumericInput
  | DraftTemplateMultiValueNumericInput => {
  switch (input.type) {
    case TemplateInputType.Boolean: {
      const { label, name, readOnly, value, visible, falseLabel, onChange, trueLabel } = input;
      const booleanInput: DraftTemplateBooleanInput = {
        type: TemplateInputType.Boolean,
        value,
        name,
        label,
        visible,
        readOnly,
        falseLabel,
        trueLabel,
        onChange,
      };
      return booleanInput;
    }
    case TemplateInputType.Numeric: {
      const { label, name, readOnly, value, visible, onChange, unit, increment, max, min } = input;
      const numericInput: DraftTemplateNumericInput = {
        type: TemplateInputType.Numeric,
        value,
        name,
        label,
        unit,
        increment,
        max,
        min,
        visible,
        readOnly,
        onChange,
      };
      return numericInput;
    }
    case TemplateInputType.MultiValueNumeric: {
      const {
        label,
        name,
        readOnly,
        value,
        visible,
        unit,
        allowCustomValue,
        onChange,
        values,
        max,
        min,
      } = input;
      const multiNumericInput: DraftTemplateMultiValueNumericInput = {
        type: TemplateInputType.MultiValueNumeric,
        value,
        name,
        label,
        unit,
        visible,
        readOnly,
        values,
        allowCustomValue,
        onChange,
        max,
        min,
      };
      return multiNumericInput;
    }
    case TemplateInputType.Text: {
      const { label, name, readOnly, value, visible, unit } = input;
      const textInput: DraftTemplateTextInput = {
        type: TemplateInputType.Text,
        value,
        name,
        label,
        unit,
        visible,
        readOnly,
      };
      return textInput;
    }
    case TemplateInputType.MultiValueText: {
      const { label, name, readOnly, value, visible, unit, values } = input;
      const multiTextInput: DraftTemplateMultiValueTextInput = {
        type: TemplateInputType.MultiValueText,
        value,
        name,
        label,
        unit,
        visible,
        readOnly,
        values,
      };
      return multiTextInput;
    }
  }
};

export const draftToDCTemplate = (
  draft: DraftTemplate,
  thumbnail: string,
  datasetUrn: string,
  engine = 'DA4I',
  engineVersion = '2023',
  workspaceLocation = 'BIMDOCS',
): DynamicContentProduct => {
  // Template Inputs
  const iProperties: IPropertyInput[] = draft.iProperties.map((iProp) => ({
    category: iProp.category,
    label: iProp.label,
    name: iProp.name,
    value: iProp.value,
    readOnly: iProp.readOnly,
    type: iProp.type,
    visible: iProp.visible,
  }));

  // rules
  const rules: TemplateRule = {};
  draft.rules.forEach((rule) => {
    rules[rule.key] = {
      code: rule.code,
      ruleLabel: rule.label,
      errorMessage: rule.errorMessage,
    };
  });

  // codeBlock
  const codeBlocksWorkspace: string = JSON.stringify(draft.codeBlocksWorkspace);

  const outputs = draft.outputs.map((output) => ({
    type: output.type,
    options: output.options,
  }));

  const dcProduct: DynamicContentProduct = {
    name: draft.name,
    schemaVersion: 1,
    dataSetLocation: datasetUrn,
    tenancyId: draft.project.id,
    thumbnail,
    context: {
      projectFile: draft.inventorProject,
      topLevelAssembly: getTopLevelAssemblyPath(draft.topLevelFolder, draft.assembly),
      engine: {
        location: engine,
        version: engineVersion,
      },
      workspace: {
        location: workspaceLocation,
        folderPath: getFullFolderPath(draft.folder),
      },
    },
    rules,
    codeBlocksWorkspace,
    inputs: iProperties,
    outputs,
  };

  draft.parameters.forEach((param) => {
    const inputParam = toDCTemplateInputParameter(param);

    if (inputParam) {
      dcProduct.inputs.push(inputParam);
    }
  });

  return dcProduct;
};

export const toDCTemplateInputParameter = (
  param: DraftTemplateInputParameter,
): BooleanInput | NumericInput | MultiValueNumericInput | MultiValueTextInput | TextInput => {
  switch (param.type) {
    case TemplateInputType.Boolean: {
      const { name, label, readOnly, value, visible, falseLabel, trueLabel, onChange } = param;
      const input: BooleanInput = {
        type: TemplateInputType.Boolean,
        label,
        name,
        readOnly,
        value,
        visible,
        falseLabel,
        trueLabel,
        onChange,
      };

      return input;
    }
    case TemplateInputType.Text: {
      const { name, label, readOnly, value, visible, unit } = param;
      const input: TextInput = {
        type: TemplateInputType.Text,
        name,
        label,
        visible,
        readOnly,
        unit,
        value,
      };

      return input;
    }
    case TemplateInputType.Numeric: {
      const { name, label, readOnly, value, visible, min, max, increment, unit, onChange } = param;
      const input: NumericInput = {
        type: TemplateInputType.Numeric,
        name,
        label,
        visible,
        readOnly,
        value,
        min,
        max,
        unit,
        increment,
        onChange,
      };

      return input;
    }
    case TemplateInputType.MultiValueText: {
      const { name, label, readOnly, visible, values, unit, value } = param;

      const input: MultiValueTextInput = {
        type: TemplateInputType.MultiValueText,
        name,
        label,
        visible,
        readOnly,
        unit,
        values,
        value,
      };
      return input;
    }

    case TemplateInputType.MultiValueNumeric: {
      const {
        name,
        label,
        readOnly,
        visible,
        values,
        unit,
        value,
        min,
        max,
        allowCustomValue,
        onChange,
      } = param;

      const input: MultiValueNumericInput = {
        type: TemplateInputType.MultiValueNumeric,
        name,
        label,
        visible,
        readOnly,
        values,
        min,
        max,
        unit,
        allowCustomValue,
        value,
        onChange,
      };

      return input;
    }
  }
};

export const toDraftTemplateInputParameter = (
  param: InventorParameter,
): DraftTemplateInputParameter => {
  const type = isBooleanInventorParameter(param)
    ? TemplateInputType.Boolean
    : isTextInventorParameter(param)
    ? TemplateInputType.Text
    : isMultivaluedInventorParameter(param)
    ? typeof (param.options as string[])[0] === 'number'
      ? TemplateInputType.MultiValueNumeric
      : TemplateInputType.MultiValueText
    : TemplateInputType.Numeric;
  switch (type) {
    case TemplateInputType.Boolean: {
      const input: DraftTemplateBooleanInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: /true/i.test(param.value),
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.Text: {
      const input: DraftTemplateTextInput = {
        type: TemplateInputType.Text,
        value: param.value,
        unit: param.unitType,
        name: param.name,
        label: param.label ?? '',
        visible: true,
        readOnly: false,
      };

      return input;
    }
    case TemplateInputType.Numeric: {
      const input: DraftTemplateNumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: Number(param.value),
        unit: param.unitType,
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.MultiValueNumeric: {
      const input: DraftTemplateMultiValueNumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: param.options as number[],
        unit: param.unitType,
        value: Number(param.value),
        onChange: [],
        allowCustomValue: true,
      };

      return input;
    }
    case TemplateInputType.MultiValueText: {
      const input: DraftTemplateMultiValueTextInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: param.options as string[],
        unit: param.unitType,
        value: param.value,
      };

      return input;
    }
  }
};

export const toDraftTemplateIProperty = (p: IProperty): DraftTemplateIProperty => ({
  id: p.id,
  type: TemplateInputType.IProperty,
  category: p.category,
  name: p.displayName,
  label: p.label ?? '',
  readOnly: false,
  value: p.value,
  visible: true,
});

export const getFullFolderPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.id) ?? []), metaInfo.id]?.join('/');

export const getFullFolderNamedPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.name) ?? []), metaInfo.name]?.join('/');

export const publishDraftTemplate = async (
  draftTemplate: DraftTemplate,
): Promise<DraftTemplatePublishResult> => await publishProductFromDraft(draftTemplate);
