import { Icon } from '@adsk/alloy-react';
import styled from 'styled-components';
import { treeBranchNodeHighlightCSS } from '../MIDTree/MIDTree.styles';

export const EmptyStateWrapper = styled.div`
  width: ${({ theme }) => `${theme.vars.folderBrowserTreeWidth}px`};
  border: 1px solid ${({ theme }) => theme.colors.charcoal300};
  text-align: center;
  height: 100%;
  margin: ${({ theme }) => `${theme.vars.marginBase + 4}px`} 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductFolderBrowserContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => `${theme.vars.productFolderBrowserComponentHeight}px`};
`;
export const TreeContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const ProductListContainer = styled.div`
  width: ${({ theme }) => `${theme.vars.folderBrowserTreeWidth}px`};
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const ProductsList = styled.div`
  margin: ${({ theme }) => `${theme.vars.marginBase + 4}px`} 0 0 0;
  border: 1px solid ${({ theme }) => theme.colors.charcoal300};
  max-height: ${({ theme }) => `${theme.vars.productFolderBrowserComponentHeight}px`};
  overflow-y: auto;
`;

export const ProductCell = styled.div<{ selected?: boolean; selectable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'not-allowed')};
  min-height: ${({ theme }) => `${theme.vars.treeCellMinHeight}px`};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.selectedTreeCellBackgroundColor : 'inherit'};
  ${treeBranchNodeHighlightCSS}
  ${({ theme }) => theme.typography.bodyMedium}
`;

export const ProductIcon = styled(Icon)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase * 2}px`};
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.vars.marginBase}px`};
  padding: 0;
  cursor: inherit;
`;

export const Title = styled(Text)`
  margin: ${({ theme }) => `${theme.vars.marginBase}px`} 0 0 0;
`;
