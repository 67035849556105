import { ReactNode } from 'react';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { TreeItem } from '../MIDTree/MIDTree.types';
import {
  filterProductsByParentFolder,
  getEmptyFolderDataView,
  getEmptyProductDataView,
} from './ProductFolderBrowser.utils';

interface UseProductFolderBrowserProps {
  projectId: string;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContentProduct[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  onProductClick?: (contentId: string | undefined) => void;
}
interface UseProductFolderBrowserState {
  emptyFolderDataView: ReactNode;
  emptyProductDataView: ReactNode;
  productsInSelectedFolder: DynamicContentProduct[] | null;
  handleProductClick: (contentId: string | undefined) => () => void;
}

export const useProductFolderBrowser = ({
  projectId,
  rootFolders,
  rootFoldersLoading,
  rootFoldersError,
  selectedFolderTreeElement,
  products,
  productsLoading,
  productsError,
  onProductClick,
}: UseProductFolderBrowserProps): UseProductFolderBrowserState => {
  const emptyFolderDataView: ReactNode = getEmptyFolderDataView(
    projectId,
    rootFolders,
    rootFoldersLoading,
    rootFoldersError,
  );

  const selectedFolderUrn: string | undefined = selectedFolderTreeElement?.id;
  const productsInSelectedFolder: DynamicContentProduct[] | null = filterProductsByParentFolder(
    products,
    selectedFolderUrn,
  );
  const emptyProductDataView: ReactNode = getEmptyProductDataView(
    productsLoading,
    productsError,
    selectedFolderUrn,
    productsInSelectedFolder,
  );

  const handleProductClick = (contentId: string | undefined) => () => {
    if (onProductClick) {
      onProductClick(contentId);
    }
  };

  return {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder,
    handleProductClick,
  };
};
