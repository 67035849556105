import { IdType } from '@adsk/alloy-react-tree/es/types';
import { TreeItem } from '../MIDTree.types';

export enum LoadState {
  IDLE,
  LOADING,
}

export interface AddChildPayloadType {
  targetId: IdType;
  newChildNodes: TreeItem[];
}

export type GetChildNodes = (selectedItem: TreeItem) => Promise<TreeItem[]>;
