import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${({ theme }) => `${theme.vars.footerHeight}px`};
  padding: ${({ theme }) => `0 ${theme.vars.paddingBase * 2}px`};
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
`;
