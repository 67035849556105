import { debounce } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';

interface useTextParameterCustomizationProps {
  value: string;
  onTextValueChange: (newValue: string) => void;
}

interface useTextParameterCustomizationState {
  localValue?: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DEBOUNCE_DELAY_IN_MS = 500;

export const useTextParameterCustomization = ({
  value,
  onTextValueChange,
}: useTextParameterCustomizationProps): useTextParameterCustomizationState => {
  const [localValue, setLocalValue] = useState<string | undefined>(value);

  const _handleDebounceTextValueChange = useMemo(
    () => debounce(onTextValueChange, DEBOUNCE_DELAY_IN_MS),
    [onTextValueChange],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    setLocalValue(currentValue);
    _handleDebounceTextValueChange(currentValue);
  };

  // When value is update, update the local numeric value.
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return {
    localValue,
    handleInputChange,
  };
};

export default useTextParameterCustomization;
