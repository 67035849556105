import { SetupWorkerApi } from 'msw';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from '../Common/global/reportWebVitals';
import { GlobalStyle } from '../Common/global/styles/Common/Common.styles';
import { AddInWindow } from '../Common/global/types';
import mockHostApi from '../lib/mocks/hostApi';
import App from './App';

if (process.env.NODE_ENV === 'development' && process.env.LOCAL_DEVELOPMENT === 'browser') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mockWorker: SetupWorkerApi = require('../lib/mocks/serviceWorker').default;
  // Starting msw mock worker to intercept FORGE API calls
  mockWorker.start();

  // Mocking HostApi library
  (window as AddInWindow).hostApi = mockHostApi;
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
