import styled from 'styled-components';

export const RadioGroupLabel = styled.label`
  align-tems: center;
  display: flex;
`;

export const RadioGroupLabelText = styled.span`
  padding-left: ${({ theme }) => `${theme.vars.paddingBase}px`};
`;
