import { BIM360Project } from '../../interfaces/forge-api';

export const mockProject: BIM360Project = {
  id: '8e62b463-b77e-4e8d-af0f-f515970debd2',
  accountId: '622b7a83-24bf-4ab8-9566-edc9e8ca2a21',
  addressLine1: '',
  addressLine2: '',
  adminGroupId: '47229522',
  businessUnitId: null,
  city: '',
  classification: 'production',
  companyCount: 1,
  constructionType: 'New Construction',
  contractType: null,
  country: 'US',
  createdAt: '2020-08-07T12:03:44.774Z',
  currentPhase: null,
  deliveryMethod: null,
  endDate: '2021-08-06',
  imageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
  jobNumber: '',
  lastSignIn: null,
  latitude: '39.390897',
  longitude: '-99.066067',
  memberCount: 1,
  memberGroupId: '47229521',
  name: 'Spike_Project_05',
  platform: 'bim360',
  postalCode: '',
  projectValue: { value: null, currency: 'USD' },
  products: [],
  sheetCount: null,
  startDate: '2020-08-07',
  stateOrProvince: '',
  status: 'active',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/thumb/project-default-1.0.png',
  timezone: 'America/New_York',
  type: 'Convention Center',
  updatedAt: '2021-09-15T18:24:04.033Z',
};

export const mockProject2: BIM360Project = {
  id: '11111111-b77e-4e8d-af0f-f515970debd2',
  accountId: '11111111-24bf-4ab8-9566-edc9e8ca2a21',
  addressLine1: '',
  addressLine2: '',
  adminGroupId: '111111',
  businessUnitId: null,
  city: '',
  classification: 'production',
  companyCount: 1,
  constructionType: 'Old Construction',
  contractType: null,
  country: 'US',
  createdAt: '2020-08-07T12:03:44.774Z',
  currentPhase: null,
  deliveryMethod: null,
  endDate: '2021-08-06',
  imageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
  jobNumber: '',
  lastSignIn: null,
  latitude: '39.390897',
  longitude: '-99.066067',
  memberCount: 1,
  memberGroupId: '47229521',
  name: 'Mocked_Project_100',
  platform: 'bim360',
  postalCode: '',
  projectValue: { value: null, currency: 'USD' },
  products: [],
  sheetCount: null,
  startDate: '2020-08-07',
  stateOrProvince: '',
  status: 'active',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/thumb/project-default-1.0.png',
  timezone: 'America/New_York',
  type: 'Convention Center',
  updatedAt: '2021-09-15T18:24:04.033Z',
};

export const mockProject3: BIM360Project = {
  id: '22222222-b77e-4e8d-af0f-f515970debd2',
  accountId: '22222222-24bf-4ab8-9566-edc9e8ca2a21',
  addressLine1: '',
  addressLine2: '',
  adminGroupId: '22222222',
  businessUnitId: null,
  city: '',
  classification: 'production',
  companyCount: 1,
  constructionType: 'New Construction',
  contractType: null,
  country: 'US',
  createdAt: '2020-08-07T12:03:44.774Z',
  currentPhase: null,
  deliveryMethod: null,
  endDate: '2021-08-06',
  imageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
  jobNumber: '',
  lastSignIn: null,
  latitude: '39.390897',
  longitude: '-99.066067',
  memberCount: 1,
  memberGroupId: '47229521',
  name: 'Project_Sample',
  platform: 'bim360',
  postalCode: '',
  projectValue: { value: null, currency: 'USD' },
  products: [],
  sheetCount: null,
  startDate: '2020-08-07',
  stateOrProvince: '',
  status: 'active',
  thumbnailImageUrl:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/thumb/project-default-1.0.png',
  timezone: 'America/New_York',
  type: 'Convention Center',
  updatedAt: '2021-09-15T18:24:04.033Z',
};
