import React from 'react';
import { SingleSelectMenu, useDropdown, MenuItem, Selected } from '@adsk/alloy-react-dropdown';
import {
  DropdownComponent,
  DropdownControl,
  DropdownIconContainer,
  DropdownTitle,
} from './Dropdown.styles';
import { DropdownItem } from './Dropdown.types';
import { Icon } from '@adsk/alloy-react';
import { MetaInfo } from '../../../lib/interfaces/templates';

export interface DropdownProps {
  elements: DropdownItem[];
  selectedItem: DropdownItem | null;
  onSelect: (item: { selectedItem?: DropdownItem | null; parentPath?: MetaInfo[] }) => void;
  title?: string;
  disabled?: boolean;
  menuItemIcon?: string;
  controlIcon?: string;
  width?: number;
  isLoadingElements?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  elements,
  title,
  menuItemIcon,
  controlIcon,
  onSelect,
  disabled,
  selectedItem,
  width,
  isLoadingElements,
}): JSX.Element => {
  const {
    isOpen,
    menuItems,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    targetProps,
    overlayProps,
  } = useDropdown({ items: elements, onSelectedItemChange: onSelect, selectedItem });

  const renderItem = (controlIcon?: string) => (item: { selectedItem?: DropdownItem | null }) =>
    (
      <DropdownIconContainer>
        {controlIcon && <Icon type={controlIcon as any} fr={0} />}
        {item.selectedItem && <Selected>{item.selectedItem?.value}</Selected>}
      </DropdownIconContainer>
    );
  const renderMenuItemLeftComponent = () => (
    <DropdownIconContainer>
      {menuItemIcon && <Icon fr={0} type={menuItemIcon as any} />}
    </DropdownIconContainer>
  );
  const renderMenuItem = (props: any) => (
    <MenuItem {...props} renderLeftComponent={renderMenuItemLeftComponent} />
  );
  return (
    <DropdownComponent width={width}>
      {title && <DropdownTitle>{title}</DropdownTitle>}
      <DropdownControl<DropdownItem>
        isOpen={isOpen}
        selectedItem={selectedItem}
        getToggleButtonProps={getToggleButtonProps}
        getLabelProps={getLabelProps}
        targetProps={targetProps}
        isDisabled={disabled}
        renderItem={renderItem(controlIcon)}
        isLoading={isLoadingElements}
      />
      <SingleSelectMenu
        isOpen={isOpen}
        overlayProps={overlayProps}
        menuItems={menuItems}
        targetProps={targetProps}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedItem}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        renderMenuItem={renderMenuItem}
      />
    </DropdownComponent>
  );
};

export default Dropdown;
