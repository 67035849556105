import { IconType } from '@adsk/alloy-react';
import { debounce, isUndefined } from 'lodash';
import { useMemo, useEffect, useState } from 'react';
import { validateNumericValue } from './utils';

interface useNumericParameterCustomizationProps {
  value: number;
  unit: string;
  readOnly: boolean;
  min?: number;
  max?: number;
  increment?: number;
  setIsFormDataValid?: (isFormValid: boolean) => void;
  onNumericValueChange: (newValue: number) => void;
}

interface useNumericParameterCustomizationState {
  numericValue?: number;
  tooltipContent?: string;
  tooltipIconType?: IconType;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isNumericValueValid: boolean;
}

const DEBOUNCE_DELAY_IN_MS = 200;
const EnterKey = 'Enter';

export const useNumericParameterCustomization = ({
  value,
  unit,
  min,
  max,
  increment,
  onNumericValueChange,
  setIsFormDataValid,
}: useNumericParameterCustomizationProps): useNumericParameterCustomizationState => {
  const [tooltipIconType, setTooltipIconType] = useState<IconType | undefined>(undefined);
  const [tooltipContent, setTooltipContent] = useState<string | undefined>(undefined);
  const [numericValue, setNumericValue] = useState<number | undefined>(value);
  const [isNumericValueValid, setIsNumericValueValid] = useState<boolean>(true);

  const _validateAndAutoCorrectNumber = (newValue: number) => {
    //Cancel any pending debounces
    _handleDebouncedUpdateNumericValue.cancel();

    //Then AutoCorrect
    const { valid, invalidType, message, correctedValue } = validateNumericValue(newValue, unit, {
      min,
      max,
      increment,
    });
    if (!valid) {
      setTooltipIconType(invalidType);
      setTooltipContent(message);
      setNumericValue(correctedValue);
      correctedValue && onNumericValueChange(correctedValue);
    } else {
      setTooltipIconType(undefined);
      setTooltipContent(undefined);
      setNumericValue(newValue);
      onNumericValueChange(newValue);
    }
    setIsNumericValueValid(true);
    setIsFormDataValid && setIsFormDataValid(true);
  };

  const _handleDebouncedUpdateNumericValue = useMemo(
    () =>
      debounce((newValue: number) => {
        const { valid } = validateNumericValue(newValue, unit, {
          min,
          max,
          increment,
        });
        if (valid) {
          setTooltipIconType(undefined);
          setTooltipContent(undefined);
        }
        setIsNumericValueValid(valid);
        onNumericValueChange(newValue);
        setIsFormDataValid && setIsFormDataValid(valid);
      }, DEBOUNCE_DELAY_IN_MS),
    [increment, max, min, unit, onNumericValueChange, setIsFormDataValid],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setNumericValue(newValue);
    _handleDebouncedUpdateNumericValue(newValue);
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(event.target.value);
    _validateAndAutoCorrectNumber(currentValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Trigger Auto Correct when EnterKey is pressed
    if (event.key === EnterKey && !isUndefined(numericValue)) {
      event.preventDefault();
      _validateAndAutoCorrectNumber(numericValue);
    }
  };

  useEffect(() => {
    setNumericValue(value);
  }, [value, setNumericValue]);

  return {
    numericValue,
    tooltipContent,
    tooltipIconType,
    handleInputChange,
    handleOnBlur,
    handleKeyDown,
    isNumericValueValid,
  };
};

export default useNumericParameterCustomization;
