import { useCallback, useEffect, useRef, useState } from 'react';
import { getDownloadUrl } from '../../../../lib/utils/cloudStorage';
import { urlContentToBase64String, fileToBase64String } from '../../../../lib/utils/filesystem';
import logger from '../../logger';

export interface UseBase64ThumbnailState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
}

export const useBase64Thumbnail = (
  tenancyId?: string,
  thumbnailObjectKey?: string,
): UseBase64ThumbnailState => {
  const [thumbnailInBase64, setThumbnailInBase64] = useState<string | undefined>();
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [thumbnailError, setThumbnailError] = useState<string | undefined>();
  const prevThumbnailObjectKey = useRef<string | undefined>();

  const getThumbnailInBase64 = async (
    tenancyId: string,
    thumbnailObjectKey: string,
  ): Promise<string> => {
    let thumbnailInBase64;
    if (thumbnailObjectKey.indexOf(':\\') > 0 || thumbnailObjectKey.indexOf(':/') > 0) {
      // string is local file path
      thumbnailInBase64 = await fileToBase64String(thumbnailObjectKey);
    } else {
      // Retrieve the download url
      const downloadUrl = await getDownloadUrl(tenancyId, thumbnailObjectKey);
      // Transform the file into base64
      thumbnailInBase64 = await urlContentToBase64String(downloadUrl);
    }

    return thumbnailInBase64.base64;
  };

  const fetchThumbnail = useCallback(async (tenancyId: string, thumbnailObjectKey: string) => {
    try {
      setThumbnailLoading(true);
      const thumbnailInBase64 = await getThumbnailInBase64(tenancyId, thumbnailObjectKey);
      prevThumbnailObjectKey.current = thumbnailObjectKey;
      setThumbnailInBase64(thumbnailInBase64);
    } catch (err) {
      setThumbnailInBase64(undefined);
      setThumbnailError(err as string);
      logger.error(err as string);
    } finally {
      setThumbnailLoading(false);
    }
  }, []);

  useEffect(() => {
    if (tenancyId && thumbnailObjectKey && prevThumbnailObjectKey.current !== thumbnailObjectKey) {
      fetchThumbnail(tenancyId, thumbnailObjectKey);
    }
    if (thumbnailInBase64 && (!tenancyId || !thumbnailObjectKey)) {
      prevThumbnailObjectKey.current = undefined;
      setThumbnailInBase64(undefined);
    }
  }, [fetchThumbnail, tenancyId, thumbnailInBase64, thumbnailObjectKey]);

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
  };
};
