import { IForgeConfiguration } from '@adsk/forge-appfw-forge-configuration';

const PRD_FORGE_API_URL = 'https://developer.api.autodesk.com';
const NON_PRD_FORGE_API_URL = 'https://developer-stg.api.autodesk.com';

export enum ServiceTypes {
  FORGEAPI = 'FORGE_API',
}

export enum Environment {
  DEV = 'dev',
  STG = 'stg',
  PRD = 'prd',
}

export const Endpoints = {
  BIM360_API_PATH: 'bim360/admin/v1',
  ACC_API_PATH: 'construction/admin/v1',
  DM_API_PATH: 'dm/v1',
  DM_STG_API_PATH: 'dm-staging/v1',
};

export const ServiceConfigMap = {
  [ServiceTypes.FORGEAPI]: {
    [Environment.PRD]: {
      api: PRD_FORGE_API_URL,
    },
    [Environment.STG]: {
      api: NON_PRD_FORGE_API_URL,
    },
    [Environment.DEV]: {
      api: NON_PRD_FORGE_API_URL,
    },
  },
};

export const isProdEnvironment = (env: Environment): boolean => env === Environment.PRD;

export const forgeConfig: IForgeConfiguration = {
  environmentBaseUrl:
    ServiceConfigMap[ServiceTypes.FORGEAPI][
      (process.env.ENVIRONMENT as Environment) || Environment.DEV
    ].api,
};

export const authenticationConfig = {
  clientId: process.env.AUTH_CONFIG_CLIENT_ID || 'CONFIGURE_IN_ENV',
  authenticationEndpoint: `${forgeConfig.environmentBaseUrl}/authentication/v1/authorize`,
  callbackUrl: (): string => window.location.origin + '/',
  scope:
    'data:read data:write data:create data:search bucket:create bucket:read bucket:update bucket:delete account:read',
};
