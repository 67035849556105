import { DynamicContentInput } from '../../../lib/interfaces/dynamicContent';
import { ConfigurableProductProperties } from './dataStore.types';

export enum ConfigurableProductActionTypes {
  INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD = 'INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD',
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_INPUT_WITH_CODE_RUNNER = 'UPDATE_INPUT_WITH_CODE_RUNNER',
}

export type ConfigurableProductActions =
  | { type: ConfigurableProductActionTypes.UPDATE_INPUT; payload: DynamicContentInput }
  | {
      type: ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD;
      payload: ConfigurableProductProperties;
    }
  | {
      type: ConfigurableProductActionTypes.UPDATE_INPUT_WITH_CODE_RUNNER;
      payload: DynamicContentInput[];
    };
