import React from 'react';
import { SummaryTableWrapper } from './SummaryTable.styles';

export interface SummaryTableRow {
  title: string;
  value: string;
}

interface SummaryTableData {
  data: SummaryTableRow[];
}

const SummaryTableRowItem: React.FC<SummaryTableRow> = ({ title, value }): JSX.Element => (
  <>
    <td>
      <strong>{title}</strong>
    </td>
    <td>{value}</td>
  </>
);

const SummaryTable: React.FC<SummaryTableData> = ({ data }): JSX.Element => (
  <SummaryTableWrapper>
    <tbody>
      {data.map((row) => (
        <tr key={row.title}>
          <SummaryTableRowItem title={row.title} value={row.value} />
        </tr>
      ))}
    </tbody>
  </SummaryTableWrapper>
);

export default SummaryTable;
