import React, { useContext } from 'react';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import { ProductSelectionScreen } from '../../screens/ProductSelectionScreen';
import { ProductCustomizationScreen } from '../../screens/ProductCustomizationScreen';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen } = useContext(NavigationContext);

  if (currentScreen === Screens.PRODUCT_SELECTION) {
    return <ProductSelectionScreen {...props} />;
  }
  if (currentScreen === Screens.PRODUCT_CUSTOMIZATION) {
    return <ProductCustomizationScreen {...props} />;
  }
  return <ProductSelectionScreen {...props} />;
};
