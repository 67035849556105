export type InventorInput = {
  name: string;
  value: string;
  isProperty: boolean;
};

export enum InventorOutputType {
  RFA = 'RFA',
  THUMBNAIL = 'Thumbnail',
}

export type InventorOutput = {
  type: InventorOutputType;
  modelStates?: string[];
};

export type InventorOutputFileInfo = {
  type: InventorOutputType;
  modelState: string;
  filePath: string;
};

export type GenerateOutputsResult = {
  success: boolean;
  outputFiles?: InventorOutputFileInfo[];
  report: string;
};
