import React, { useContext } from 'react';
import { CheckboxLabel, ModalBody, StyledModal, StyledSpan } from './ConfirmationModal.styles';
import ModalContext from '../../context/GlobalModal.context';
import { Checkbox } from '@adsk/alloy-react';

export const ConfirmationModal: React.FC = (): JSX.Element => {
  const {
    isOpen,
    message,
    title,
    dontShowAgainMessage,
    dontShowAgain,
    cancelButtonLabel,
    confirmButtonLabel,
    disableConfirmButton,
    handleConfirm,
    handleCancel,
    handleDontShowAgainClick,
  } = useContext(ModalContext);
  return (
    <StyledModal
      open={isOpen}
      title={title}
      cancelButtonLabel={cancelButtonLabel}
      confirmationButtonLabel={confirmButtonLabel}
      isConfirmationButtonDisabled={disableConfirmButton}
      message={
        <ModalBody>
          {message}
          {dontShowAgainMessage && (
            <CheckboxLabel>
              <Checkbox checked={dontShowAgain} onChange={handleDontShowAgainClick} />
              <StyledSpan>{dontShowAgainMessage}</StyledSpan>
            </CheckboxLabel>
          )}
        </ModalBody>
      }
      onConfirm={handleConfirm}
      onClose={handleCancel}
    />
  );
};
