import styled from 'styled-components';

export const SummaryTableWrapper = styled.table`
  border-collapse: collapse;
  border: 2px solid ${({ theme }) => theme.colors.charcoal100};
  width: 100%;

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.charcoal100};
  }

  td {
    text-align: left;
    padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  }

  td:first-child {
    width: 40%;
  }
`;
