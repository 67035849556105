import { EmptyState, IllustrationType, ILLUSTRATION_TYPES, Size } from '@adsk/alloy-react';
import React from 'react';
import text from '../../global/text/text.json';

export interface MIDEmptyStateProps {
  title?: React.ReactNode;
  illustrationType?: IllustrationType | undefined;
  description?: React.ReactNode;
  hideIllustration?: boolean | undefined;
  size?: Size | undefined;
}

export const MIDEmptyState: React.FC<MIDEmptyStateProps> = ({
  title = text.EmptyStateNoData,
  hideIllustration,
  illustrationType = ILLUSTRATION_TYPES.FILING_CABINET_GREY,
  description,
  size = EmptyState.SIZES.MEDIUM,
}): JSX.Element => (
  <EmptyState
    description={description}
    illustrationType={illustrationType}
    hideIllustration={hideIllustration}
    title={title}
    size={size}
  />
);

export default MIDEmptyState;
