import { Button, ILLUSTRATION_TYPES, ProgressRing } from '@adsk/alloy-react';
import React, { useContext } from 'react';
import { ACCDocSelection } from '../../Common/components/ACCDocSelection/ACCDocSelection';
import { useACCDocSelection } from '../../Common/components/ACCDocSelection/useACCDocSelection';
import MIDEmptyState from '../../Common/components/EmptyState/MIDEmptyState';
import ProductFolderBrowser from '../../Common/components/ProductFolderBrowser/ProductFolderBrowser';
import useProductSelection from '../../Common/components/ProductSelectionHook/useProductSelection';
import {
  FlexContainer,
  GridContainer,
  LocationInformation,
} from '../../Common/global/styles/Common/Common.styles';
import text from '../../Common/global/text/text.json';
import { ConfirmSelectionsButton } from '../../Inventor/components/Publishing/Publishing.styles';
import { OutputType } from '../../lib/interfaces/templates';
import { ProductDetails } from '../components/ProductDetails/ProductDetails';
import DataContext from '../context/Data/Data.context';
import {
  PreviewLoader,
  PreviewInProgressMessageWrap,
  PreviewImage,
  PreviewWrapper,
} from './Screens.styles';
import useProductSelectionScreen from './useProductSelectionScreen';

export const ProductSelectionScreen: React.FC = () => {
  const { currentProduct } = useContext(DataContext);
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();
  const {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleProductClick,
    products,
    productsLoading,
    productsError,
    selectedProduct,
  } = useProductSelection({ selectedProjectId: selectedProject?.id });

  const { thumbnailError, thumbnailInBase64, thumbnailLoading, handleCustomizeClick } =
    useProductSelectionScreen({ selectedProduct });

  const rfaOutput = currentProduct
    ? currentProduct.outputs.find((output) => output.type === OutputType.RFA)
    : null;

  return (
    <>
      <LocationInformation>{text.revitProjectSelectionDescription}</LocationInformation>
      <GridContainer>
        <ACCDocSelection
          accounts={accounts || []}
          accountsLoading={accountsLoading}
          projects={projects || []}
          projectsLoading={projectsLoading}
          selectedAccount={selectedAccount}
          selectedProject={selectedProject}
          projectsDisabled={projectsDisabled}
          handleSelectAccount={handleSelectAccount}
          handleSelectProject={handleSelectProject}
        />
        <ProductFolderBrowser
          projectId={selectedProject?.id || ''}
          rootFolders={rootFoldersTreeItems}
          rootFoldersLoading={rootFoldersLoading}
          rootFoldersError={rootFoldersError}
          selectedFolderTreeElement={selectedFolderTreeElement}
          products={products}
          productsLoading={productsLoading}
          productsError={productsError}
          selectedProductId={selectedProduct?.contentId}
          onFolderClick={handleFolderClick}
          onProductClick={handleProductClick}
        />
        <FlexContainer>
          <ConfirmSelectionsButton
            variant={Button.VARIANTS.PRIMARY}
            disabled={!currentProduct?.contentId}
            onClick={handleCustomizeClick}
          >
            {text.revitCustomizeProduct}
          </ConfirmSelectionsButton>
        </FlexContainer>
        {currentProduct?.contentId && (
          <div>
            <PreviewWrapper>
              {thumbnailLoading ? (
                <PreviewLoader>
                  <ProgressRing size={ProgressRing.SIZES.LARGE} />
                  <PreviewInProgressMessageWrap>
                    {text.revitLoadingThumbnail}
                  </PreviewInProgressMessageWrap>
                </PreviewLoader>
              ) : thumbnailInBase64 ? (
                <PreviewImage
                  src={`data:image/jpeg;base64,${thumbnailInBase64}`}
                  alt={text.previewImageAltText}
                />
              ) : (
                <MIDEmptyState
                  illustrationType={
                    thumbnailError ? ILLUSTRATION_TYPES.SYSTEM_ERROR : ILLUSTRATION_TYPES.PAGE_PHOTO
                  }
                  title={thumbnailError ? text.revitPreviewNotAvailable : ''}
                />
              )}
            </PreviewWrapper>
            <ProductDetails
              title={currentProduct.name || ''}
              productCategory={rfaOutput?.options?.category || ''}
              productFamily={rfaOutput?.options?.family || ''}
              productLevelOfDetail={rfaOutput?.options?.modelStates?.[0] || ''}
            />
          </div>
        )}
      </GridContainer>
    </>
  );
};
