import { HostApi } from '../interfaces/cefSharp';
declare let hostApi: HostApi;

export const showDevTools = (): Promise<void> => hostApi?.showDevTools();

export const reloadBrowser = (): Promise<void> => hostApi?.reloadBrowser();

export const getAssemblyVersion = (): Promise<string> => hostApi?.getAssemblyVersion();

export const openMidWebapp = async (): Promise<boolean> => {
  const result = await hostApi?.openExternalUrl(await hostApi?.getMIDWebAppUrl());
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};
