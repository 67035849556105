import { Button, FormField, Icon, MenuItem, MenuList, ProgressRing } from '@adsk/alloy-react';
import { SkeletonLoadingVertebra, TableBodyCell } from '@adsk/alloy-react-table';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  html {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }
`;

export const LeftButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const RightButton = styled(Button)<{ width?: number }>`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  white-space: nowrap;
`;

export const MiddleSection = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const MiddleButton = styled(Button)`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;

export const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.labelMedium}
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const LabelText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.charcoal700};
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  ${({ theme }) => theme.typography.labelSmall}
`;

export const LabelTextRequired = styled.span`
  color: ${({ theme }) => theme.colors.red600};
  margin-left: 3px;
`;

export const TooltipWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;

export const TooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.black};
  vertical-align: top;
`;

export const MIDFormField = styled(FormField)`
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const TabContainer = styled.div`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.vars.tabsVerticalMargin}px`} ${({ theme }) =>
    `${theme.vars.marginBase}px`};
`;

export const TabFormContainer = styled.form`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.vars.tabsVerticalMargin}px`} ${({ theme }) =>
    `${theme.vars.marginBase}px`};
`;

export const FieldSetContainer = styled.fieldset`
  margin: 0 0 ${({ theme }) => `${theme.vars.marginBase}px`} 0;
  padding: ${({ theme }) => `${theme.vars.paddingBase * 3}px`};
  border: none;
  background-color: ${({ theme }) => theme.colors.charcoal100};
`;

export const RowItem = styled.div<{ justifyContent?: string }>`
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  flex: 1;
  display: flex;
`;

export const FieldsetRow = styled.div`
  ${({ theme }) => theme.typography.bodyMedium}
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  display: flex;
`;

export const FieldsetGrid = styled.div`
  display: grid;

  // Basic Grid 2x2. Should be extended in order to customize that
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;

  column-gap: ${({ theme }) => `${theme.vars.marginBase}px`};
  row-gap: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const FieldsetGridLabel = styled.label`
  align-self: center;
`;

export const InputFormField = styled(FormField)<{ width: string }>`
  width: ${({ width }) => width};
  margin-right: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const TablesCells = styled(TableBodyCell)<{ index: number; isSelected: boolean }>`
  background-color: ${(row) =>
    row.index % 2 === 0
      ? row.isSelected
        ? `${row.theme.colors.adskBlue500Alpha15} !important`
        : 'inherit'
      : row.isSelected
      ? `${row.theme.colors.adskBlue100} !important`
      : `${row.theme.colors.charcoal100}`};
`;
const LoadingAnimation = keyframes`
  0% { background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
 `;

export const StyledSkeletonLoadingVertebra = styled(SkeletonLoadingVertebra)`
  background: linear-gradient(
    90deg,
    ${({ theme }) => `${theme.colors.charcoal100}`},
    ${({ theme }) => `${theme.colors.charcoal400}`},
    ${({ theme }) => `${theme.colors.charcoal100}`}
  );
  background-size: 200% auto;

  -webkit-animation: ${LoadingAnimation} 1s ease-in-out infinite;
  -moz-animation: ${LoadingAnimation} 1s ease-in-out infinite;
  animation: ${LoadingAnimation} 1s ease-in-out infinite;
`;

export const SpinnerForButton = styled(ProgressRing)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const FlexContainer = styled.div<{ alignItems?: string; justifyContent?: string }>`
  display: flex;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  gap: ${({ theme }) => `${theme.vars.marginBase}px`};
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const LocationContainer = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const LocationInformation = styled.h2`
  margin: 0;
  padding: ${({ theme }) => `${theme.vars.paddingBase * 0.5}px`} 0
    ${({ theme }) => `${theme.vars.paddingBase * 2}px`}
    ${({ theme }) => `${theme.vars.paddingBase}px`};
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const SidebarComponent = styled.div`
  display: block;
  width: 20%;
  min-width: 200px;
`;

export const SidebarButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;

export const SidebarMenu = styled(MenuList)`
  overflow: auto;
  margin-top: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.charcoal200};
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.editInputsMenuHeight}px`}
  );
`;

export const SidebarItem = styled(MenuItem)`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.charcoal100};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.adskBlue300};
  }
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.adskBlue500 : undefined} !important;
`;

export const ContentWrapper = styled.div`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  width: 80%;
  display: block;
`;

export const SidebarTitle = styled.h1`
  height: ${({ theme }) => `${theme.vars.heading2}px`};
  margin: 0;
  ${({ theme }) => theme.typography.heading2}
`;
