import { HostApi } from '../interfaces/cefSharp';
import { DynamicContentVariant } from '../interfaces/dynamicContent';
import { getDownloadUrl } from './cloudStorage';

declare let hostApi: HostApi;

export const insertRFA = async (variant: DynamicContentVariant): Promise<void> => {
  const { name, outputs, variantId, tenancyId, contentId } = variant;
  // find first rfa output, assume only one RFA output
  const rfaOutput = outputs.find((output) => output.type.toLowerCase() === 'rfa');

  // throw error if variant doesn't have a finished rfa output
  if (!rfaOutput) {
    throw new Error(`Variant doesn't contain a RFA output.`);
  }

  if (!rfaOutput.urn) {
    throw new Error(`RFA output of variant doesn't contain an object key.`);
  }

  if (!rfaOutput.modelState) {
    throw new Error(`RFA output of variant doesn't contain a model state.`);
  }

  if (!rfaOutput.category) {
    throw new Error(`RFA output of variant doesn't contain a category.`);
  }

  // retrieve a short-lived signed URL for download the file
  const sourceLocation =
    rfaOutput.urn.indexOf(':\\') > 0
      ? rfaOutput.urn
      : await getDownloadUrl(variant.tenancyId, rfaOutput.urn);

  return hostApi.insertRFA(
    tenancyId,
    contentId,
    variantId,
    sourceLocation,
    name,
    rfaOutput.category,
  );
};
