import { useContext, useEffect } from 'react';
import { useBase64Thumbnail } from '../../Common/global/hooks/productMetadata/hooks';
import { DynamicContentProduct } from '../../lib/interfaces/dynamicContent';
import DataContext from '../context/Data/Data.context';
import { initialProductTemplate } from '../context/Data/dataStore';
import NavigationContext from '../context/Navigation/Navigation.context';
import { Screens } from '../context/Navigation/navigationStore';

interface useProductSelectionScreenState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  handleCustomizeClick: () => void;
}

interface useProductSelectionScreenProps {
  selectedProduct: DynamicContentProduct | undefined;
}

export const useProductSelectionScreen = ({
  selectedProduct,
}: useProductSelectionScreenProps): useProductSelectionScreenState => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { currentProduct, setCurrentProduct } = useContext(DataContext);

  useEffect(() => {
    if (selectedProduct) {
      setCurrentProduct(selectedProduct);
    } else {
      setCurrentProduct(initialProductTemplate);
    }
  }, [selectedProduct, setCurrentProduct]);

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    currentProduct.tenancyId,
    currentProduct.thumbnail,
  );

  const handleCustomizeClick = () => {
    setCurrentScreen(Screens.PRODUCT_CUSTOMIZATION);
  };

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    handleCustomizeClick,
  };
};

export default useProductSelectionScreen;
