import React from 'react';
import MIDTree from '../MIDTree/MIDTree';
import { TreeItem } from '../MIDTree/MIDTree.types';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { MetaInfo } from '../../../lib/interfaces/templates';
import {
  ProductCell,
  ProductFolderBrowserContainer,
  ProductIcon,
  ProductListContainer,
  ProductsList,
  TreeContainer,
  Text,
  Title,
} from './ProductFolderBrowser.styles';
import { getChildNodes } from './ProductFolderBrowser.utils';
import { ICON_TYPES } from '@adsk/alloy-react';
import { useProductFolderBrowser } from './useProductFolderBrowser';
import text from '../../global/text/text.json';

export interface ProductFolderBrowserProps {
  projectId: string;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContentProduct[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  selectedProductId?: string;
  onFolderClick: (element: TreeItem, path: MetaInfo[]) => void;
  onProductClick?: (contentId: string | undefined) => void;
}

const ProductFolderBrowser: React.FC<ProductFolderBrowserProps> = ({
  projectId,
  rootFolders,
  rootFoldersLoading,
  rootFoldersError,
  selectedFolderTreeElement,
  products,
  productsLoading,
  productsError,
  selectedProductId,
  onFolderClick,
  onProductClick,
}) => {
  const {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder,
    handleProductClick,
  } = useProductFolderBrowser({
    projectId,
    rootFolders,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    products,
    productsLoading,
    productsError,
    onProductClick,
  });

  return (
    <ProductFolderBrowserContainer>
      <TreeContainer>
        <Title>{text.foldersInSelectedProject}</Title>
        {emptyFolderDataView || (
          <MIDTree
            treeRootElements={rootFolders}
            selectedTreeElement={selectedFolderTreeElement}
            onSelect={onFolderClick}
            getChildNodes={getChildNodes(projectId)}
          />
        )}
      </TreeContainer>
      <ProductListContainer>
        <Title>{text.productsInSelectedFolder}</Title>
        {emptyProductDataView || (
          <ProductsList>
            {productsInSelectedFolder?.map((product) => (
              <ProductCell
                data-testid="product-cell"
                key={product.contentId}
                selected={selectedProductId === product.contentId}
                selectable={!!onProductClick}
                onClick={handleProductClick(product.contentId)}
              >
                <ProductIcon type={ICON_TYPES.PHOTO} fr={0}></ProductIcon>
                <Text>{product.name}</Text>
              </ProductCell>
            ))}
          </ProductsList>
        )}
      </ProductListContainer>
    </ProductFolderBrowserContainer>
  );
};
export default ProductFolderBrowser;
