import { BIM360Account } from '../../interfaces/forge-api';

export const mockAccount: BIM360Account = {
  id: 'acct-001',
  name: 'acct-001',
  imageUrl: 'http://img-acct-001',
  thumbnailImageUrl: 'http://thb-img-acct-001',
  region: 'US',
  hasBim360Projects: true,
  adminGroupId: 'admin-grp-001',
};

export const mockAccount2: BIM360Account = {
  id: 'acct-002',
  name: 'acct-002',
  imageUrl: 'http://img-acct-002',
  thumbnailImageUrl: 'http://thb-img-acct-002',
  region: 'US',
  hasBim360Projects: true,
  adminGroupId: 'admin-grp-002',
};
