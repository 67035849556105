import React from 'react';
import { LeftSection, Subtitle } from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';

const Initial: React.FC = (): JSX.Element => (
  <LeftSection>
    <Subtitle>{text.revitSubHeaderTitleInitial}</Subtitle>
  </LeftSection>
);

export default Initial;
