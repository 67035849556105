import styled from 'styled-components';

export const DeployEnvironment = styled.div`
  background-color: #d74e26;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  margin: 1px;
  border-radius: 5px;
  padding: 2px 4px;
  font-family: 'Artifakt Element', sans-serif;
  font-size: 10px;
  font-weight: bold;
  cursor: default;
`;
