import { CheckboxState } from '@adsk/alloy-react';
import { BaseAction } from '@adsk/alloy-react-tree/es/hooks/useTreeReducer';
import { IdType } from '@adsk/alloy-react-tree/es/types';
import { useState } from 'react';
import { TreeItem } from '../MIDTree.types';
import { GetChildNodes, LoadState } from './AsyncMIDTreeNode.types';
import { addChildNodes } from './AsyncMIDTreeNode.utils';

export interface UseAsyncMIDTreeNodeProps {
  id: IdType;
  selectedItem: TreeItem;
  isExpanded: boolean;
  dispatch: React.Dispatch<BaseAction<TreeItem>>;
  getChildNodes: GetChildNodes;
  onExpand: ({ isExpanded, id }: { isExpanded: boolean; id: IdType }) => void;
  onSelect: ((p: { isSelected: CheckboxState; id: IdType }) => void) | undefined;
  filterNewChildNodes: (childNodes: TreeItem[]) => TreeItem[];
}

export interface UseAsyncMIDTreeNodeState {
  error: boolean;
  loadState: LoadState;
  handleBranchNodeClick: () => Promise<void>;
  handleLeafNodeClick: () => void;
}

const useAsyncMIDTreeNode = ({
  id,
  selectedItem,
  isExpanded,
  dispatch,
  getChildNodes,
  onExpand,
  onSelect,
  filterNewChildNodes,
}: UseAsyncMIDTreeNodeProps): UseAsyncMIDTreeNodeState => {
  const [loadState, setLoadState] = useState<LoadState>(LoadState.IDLE);
  const [error, setError] = useState<boolean>(false);

  const handleBranchNodeClick = async () => {
    if (loadState === LoadState.IDLE && !isExpanded) {
      try {
        setError(false);
        setLoadState(LoadState.LOADING);
        const allChildNodes: TreeItem[] = await getChildNodes(selectedItem);
        const newChildNodes = filterNewChildNodes(allChildNodes);
        if (newChildNodes && newChildNodes.length) {
          addChildNodes(id, newChildNodes, dispatch);
        }
        setLoadState(LoadState.IDLE);
      } catch (error) {
        setError(true);
        setLoadState(LoadState.IDLE);
      }
    }
    onExpand({
      isExpanded: !isExpanded,
      id,
    });

    _triggerOnSelect();
  };

  const handleLeafNodeClick = () => {
    _triggerOnSelect();
  };

  const _triggerOnSelect = () => {
    if (onSelect) {
      onSelect({ isSelected: true, id });
    }
  };

  return {
    error,
    loadState,
    handleBranchNodeClick,
    handleLeafNodeClick,
  };
};

export default useAsyncMIDTreeNode;
