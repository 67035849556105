import { TextInput } from '@adsk/alloy-react';
import React from 'react';
import { TextInputWrapper } from './TextParameterCustomization.styles';
import useTextParameterCustomization from './useTextParameterCustomization';

interface TextParameterCustomizationProps {
  value: string;
  readOnly: boolean;
  onTextValueChange: (newValue: string) => void;
}

const TextParameterCustomization: React.FC<TextParameterCustomizationProps> = ({
  value,
  readOnly,
  onTextValueChange,
}) => {
  const { localValue, handleInputChange } = useTextParameterCustomization({
    value,
    onTextValueChange,
  });
  return (
    <TextInputWrapper>
      <TextInput type="text" value={localValue} onChange={handleInputChange} readOnly={readOnly} />
    </TextInputWrapper>
  );
};

export default TextParameterCustomization;
