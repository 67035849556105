import React from 'react';
import { RadioButton, RadioGroup } from '@adsk/alloy-react';
import { RadioGroupLabel, RadioGroupLabelText } from './RadioButtonGroup.styles';

type RadioButtonGroupVariants = typeof RadioGroup.VARIANTS;
type RadioButtonGroupVariant = RadioButtonGroupVariants[keyof RadioButtonGroupVariants];
interface RadioButtonGroupProps {
  radioButtons: { label: string; value: string }[];
  readOnly: boolean;
  value: string;
  onRadioButtonSelectionChange: (newValue: string) => void;
  variant?: RadioButtonGroupVariant;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  radioButtons,
  readOnly,
  value,
  onRadioButtonSelectionChange,
  variant = RadioGroup.VARIANTS.VERTICAL,
}) => {
  const handleRadioSelectionChange = (newValue: string | undefined): void => {
    if (newValue) {
      onRadioButtonSelectionChange(newValue);
    }
  };

  return (
    <RadioGroup onChange={handleRadioSelectionChange} value={value} variant={variant}>
      {radioButtons.map((radioButton, index) => (
        <RadioGroupLabel
          key={`${index}-${radioButton.label}`}
          data-testid={`label-${radioButton.label}`}
        >
          <RadioButton value={radioButton.value} disabled={readOnly} />
          <RadioGroupLabelText>{radioButton.label}</RadioGroupLabelText>
        </RadioGroupLabel>
      ))}
    </RadioGroup>
  );
};
