import {
  BIM360Account,
  ForgeAccountsQueryResponse,
  ForgeDMProjectDetails,
  ForgeDMProjectFolder,
  ForgeDMProjectFoldersResponse,
  ForgeProjectsQueryResponse,
  ProjectDetails,
  ProjectFolder,
} from '../interfaces/forge-api';
import { BIM360Project } from '../interfaces/forge-api';
import { ApiService, ApiServiceFactory } from '../services/api.service';
import { Endpoints, isProdEnvironment, ServiceTypes } from '../services/config';
import { getAuthToken } from './auth';
import { HostApi } from '../interfaces/cefSharp';
import text from '../../Common/global/text/text.json';
declare let hostApi: HostApi;

export const accountsPath = `${Endpoints.BIM360_API_PATH}/accounts`;

/**
 * Retrieves all forge accounts for the user
 * @returns a collection of BIM360Account
 */
export const getAccAccounts = async (): Promise<BIM360Account[]> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }
  const env = await hostApi.getEnvironment();
  if (!env) {
    throw Error("Couldn't retrieve environment from host application.");
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, { env, token });
  return await getAllAccAccounts(apiService, accountsPath);
};

/**
 * Recursively queries all forge accounts for the user
 * @param apiService the apiService
 * @param url the url
 * @returns a collection of BIM360Account
 */
const getAllAccAccounts = async (
  apiService: ApiService,
  url?: string,
): Promise<BIM360Account[]> => {
  if (!url || !apiService) {
    return [];
  }

  const { data } = await apiService.get(url);
  const result = [...(data as ForgeAccountsQueryResponse).results];

  if (data.pagination.nextUrl) {
    const restData = await getAllAccAccounts(apiService, data.pagination.nextUrl);
    result.push(...restData);
  }

  return result;
};

/**
 * Retrieves all forge projects for the user
 * @returns a collection of BIM360Projects
 */
export const getProjects = async (accountId: string): Promise<BIM360Project[]> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }
  const env = await hostApi.getEnvironment();
  if (!env) {
    throw Error("Couldn't retrieve environment from host application.");
  }

  // the ACC Api expects a non-prefixed account id,
  // so remove the "b." prefix if there is one
  const forgeAccountId = accountId.startsWith('b.') ? accountId.slice(2) : accountId;

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, { env, token });
  // use maximum limit to reduce number of required http requests
  const projectsPath = `${Endpoints.BIM360_API_PATH}/projects?limit=200&filter[status]=active&filter[accountId]=${forgeAccountId}`;
  return await getAllProjects(apiService, projectsPath);
};

/**
 * helper function for getProjects to recursively traverse all pages
 * @param apiService
 * @param url
 */
const getAllProjects = async (apiService: ApiService, url?: string): Promise<BIM360Project[]> => {
  if (!url || !apiService) {
    return [];
  }

  // extract projects from results
  const { data } = await apiService.get(url);
  const result = [...(data as ForgeProjectsQueryResponse).results];

  // recursively collect data from all pages and append to result list
  if (data.pagination?.nextUrl) {
    const restData = await getAllProjects(apiService, data.pagination.nextUrl);
    result.push(...restData);
  }

  return result;
};

/**
 * Retrieves all folders of the given project for the user.
 * When parentUrn is provided, retrieves all sub-folders of the
 * parentUrn of the given project for the user.
 * @param projectId the project id
 * @param parentUrn the urn of the parent folder (empty for top-level folders)
 * @returns a collection of ProjectFolder
 */
export const getProjectFolders = async (
  projectId: string,
  parentUrn?: string,
): Promise<ProjectFolder[]> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }
  const env = await hostApi.getEnvironment();
  if (!env) {
    throw Error("Couldn't retrieve environment from host application.");
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, { env, token });
  const apiPath = isProdEnvironment(env) ? Endpoints.DM_API_PATH : Endpoints.DM_STG_API_PATH;
  const foldersPath = `${apiPath}/projects/${projectId}/folders/${parentUrn ?? ''}`;
  return getAllProjectFolders(apiService, foldersPath, 0);
};

/**
 * Recursively queries for all project folders. Increases offsetPage on every call
 * @param apiService the apiService
 * @param url the api url
 * @param offsetPage the number of pages to offset
 * @returns a collection of ProjectFolder
 */
const getAllProjectFolders = async (
  apiService: ApiService,
  url: string,
  offsetPage: number,
): Promise<ProjectFolder[]> => {
  if (!url || !apiService) {
    return [];
  }

  const queryPath = `${url}?limit=200&offset=${offsetPage}`;
  const { data } = (await apiService.get(queryPath)) as { data: ForgeDMProjectFoldersResponse };
  const result = data.folders?.map(toProjectFolder) ?? [];

  if (data.has_next_page) {
    const restData = await getAllProjectFolders(apiService, url, offsetPage + 1);
    result.push(...restData);
  }

  return result;
};

/**
 * Converts a ForgeDMProjectFolder to a ProjectFolder
 * @param data the data to transform
 * @returns a ProjectFolder
 */
export const toProjectFolder = (data: ForgeDMProjectFolder): ProjectFolder => {
  const { urn, title, hidden, deleted, path, ...restOfData } = data;

  return {
    urn,
    title,
    path,
    hidden,
    projectId: restOfData.project_id,
    parentUrn: restOfData.parent_urn,
    hasSubfolders: restOfData.has_subfolders,
    deleted,
    folderType: restOfData.folder_type,
    isRoot: restOfData.is_root,
    viewOption: restOfData.view_option,
    permissionType: restOfData.permission_type,
    isSystemFolder: restOfData.is_system_folder,
  } as ProjectFolder;
};

/**
 * Get the details of a given project
 * @returns Project details
 */
export const getProjectDetails = async (projectId: string): Promise<ProjectDetails> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }
  const env = await hostApi.getEnvironment();
  if (!env) {
    throw Error("Couldn't retrieve environment from host application.");
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGEAPI, { env, token });
  const apiPath = isProdEnvironment(env) ? Endpoints.DM_API_PATH : Endpoints.DM_STG_API_PATH;
  const projectPath = `${apiPath}/projects/${projectId}`;

  const { data } = (await apiService.get(projectPath)) as { data: ForgeDMProjectDetails };
  const result = toForgeProjectDetails(data) ?? [];
  return result;
};

/**
 * Converts a ForgeDMProjectDetails to a ProjectDetails
 * @param data the data to transform
 * @returns a ProjectDetails
 */
export const toForgeProjectDetails = (data: ForgeDMProjectDetails): ProjectDetails => {
  const { id, name, account_id, account_display_name } = data;

  return {
    projectId: id,
    projectName: name,
    accountId: account_id,
    accountName: account_display_name,
  } as ProjectDetails;
};
