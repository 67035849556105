import React, { useContext, useEffect, useState } from 'react';
import { VersionInfo } from '../../../Common/components/VersionInfo/VersionInfo';
import { HeaderWrapper, TopHeader } from '../../../Common/global/styles/Header/Header.styles';
import DataContext from '../../context/Data/Data.context';
import { initialProductTemplate } from '../../context/Data/dataStore';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import CustomizeProduct from './SubHeaders/CustomizeProduct';
import Initial from './SubHeaders/Initial';
import { getAssemblyVersion } from '../../../lib/utils/tools';
import { RevitAddInMoniker } from '../../../Common/global/constants';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { setCurrentProduct } = useContext(DataContext);
  const [assemblyVersion, setAssemblyVersion] = useState<string | undefined>(undefined);

  useEffect(() => {
    getAssemblyVersion().then((testAssemblyVersion) => {
      setAssemblyVersion(testAssemblyVersion);
    });
  }, []);

  const handleChangeProductClick = () => {
    setCurrentScreen(Screens.PRODUCT_SELECTION);
    setCurrentProduct(initialProductTemplate);
  };

  return (
    <HeaderWrapper>
      <TopHeader>
        {currentScreen === Screens.PRODUCT_SELECTION && <Initial />}
        {currentScreen === Screens.PRODUCT_CUSTOMIZATION && (
          <CustomizeProduct handleChangeProductClick={handleChangeProductClick} />
        )}
        <VersionInfo
          RVTW_VERSION={process.env.REACT_APP_RVTW_VERSION}
          RVTA_VERSION={assemblyVersion ? `v${assemblyVersion}+${RevitAddInMoniker}` : undefined}
        />
      </TopHeader>
    </HeaderWrapper>
  );
};

export default Header;
