import { Button } from '@adsk/alloy-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => theme.typography.bodyMedium};
  margin: auto;
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`} 0;
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.tablet}) {
    width: 70%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.laptop}) {
    width: 50%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.desktop}) {
    width: 35%;
  }
  max-height: calc(100vh - ${({ theme }) => `${theme.vars.topBars}px`});
  overflow: auto;
`;

export const TableTitle = styled.h2`
  ${({ theme }) => theme.typography.heading2};
  margin: ${({ theme }) => `${theme.vars.marginBase / 2}px`} 0;
`;

export const TemplateSummary = styled.div`
  margin: ${({ theme }) => `${theme.vars.marginBase * 2}px`} 0;
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const CompleteDialog = styled.div`
  text-align: center;
`;

export const ConfirmSelectionsButton = styled(Button)`
  margin: 0 0 ${({ theme }) => `${theme.vars.marginBase}px`}
    ${({ theme }) => `${theme.vars.marginBase * 3}px`};
  justify-items: flex-end;
  align-self: flex-end;
  white-space: nowrap;
`;

export const Title = styled.h4`
  ${({ theme }) => theme.typography.heading4}
  margin: 0;
  padding: 0 0 0 ${({ theme }) => `${theme.vars.paddingBase}px`};
`;

export const PublishingErrorMessageWrapper = styled.div`
  margin: ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  border: 1px solid ${({ theme }) => `${theme.colors.red600}`};
  color: ${({ theme }) => `${theme.colors.red600}`};
`;
