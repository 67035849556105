import { DraftTemplateInputParameter } from '../../../lib/interfaces/templates';
import {
  codeRunnerSetOutput,
  codeRunnerGetInput,
} from '../../../Inventor/components/BlocklyModule/constants';
import Interpreter from 'js-interpreter';
import Blockly from 'blockly';
import logger from '../../global/logger';
import text from '../../global/text/text.json';
import { extractBlockIdfromCode } from '../../../Inventor/components/BlocklyModule/utils';

interface CodeRunnerResult {
  hasError: boolean;
  errorMessage: string;
  result: DraftTemplateInputParameter[];
}

interface KeyValuedTemplateInputParameter {
  [key: string]: DraftTemplateInputParameter;
}

/**
 * This function runs a string of code in a sandbox environment using JS-Interpreter.
 * The code is generated by Blockly and is used to update the DraftTemplateInputParameter[] variable
 *
 * @param {string} code - Generated from blockly
 * @param {DraftTemplateInputParameter[]} inputParameters - input parameters from draft
 * @param {Blockly.WorkspaceSvg} blocklyWorkspace? - Blockly workspace to highlight faulty blocks
 *
 * @return {DraftTemplateInputParameter[]} - Updated input parameters
 *
 *  SAMPLE CODE GENERATED BY BLOCKLY:
 *
 *  if(parameters['EqualCheck'].value == true) {
 *    parameters['Width'].max = 10;
 *  }
 *  else{
 *    parameters['Width'].min = 30;
 *  }
 */

const codeRunner = (
  code: string,
  inputParameters: DraftTemplateInputParameter[],
  blocklyWorkspace?: Blockly.WorkspaceSvg,
): CodeRunnerResult => {
  const jsCode = `var parameters = JSON.parse(${codeRunnerGetInput}());
        ${code}
    ${codeRunnerSetOutput}(JSON.stringify(parameters));
  `;

  try {
    //Initialize and run code
    const myInterpreter = new Interpreter<DraftTemplateInputParameter[]>(
      jsCode,
      (interpreter, globalObject) =>
        initFunc(interpreter, globalObject, inputParameters, blocklyWorkspace),
    );
    myInterpreter.run();

    //If Blockly workspace is provided cleanup highlighted block
    if (blocklyWorkspace) {
      blocklyWorkspace.highlightBlock(null);
    }

    return {
      result: myInterpreter.value,
      hasError: false,
      errorMessage: '',
    };
  } catch (err: any) {
    // If any errors have been generated
    // Search for faulty line by splitting jsCode using `\n`
    const { line } = err.loc;
    const linesOfCode = jsCode.split('\n');
    const errorLine = linesOfCode[line - 1];

    if (blocklyWorkspace) {
      highlightFaultyBlock(blocklyWorkspace, line, linesOfCode);
    }

    logger.error(text.failToRunBlocklyCode, { jsCode, err, errorLine });

    return {
      result: inputParameters,
      hasError: true,
      errorMessage: text.failToRunBlocklyCode,
    };
  }
};

/**
 * It initializes the interpreter with the code to run.
 * It also serves as an interface to communicate with the interpreter.
 *
 * @param interpreter {Interpreter<DraftTemplateInputParameter[]>} -
 * @param globalObject {object} -
 * @param inputParameters {DraftTemplateInputParameter[]}-
 * @param blocklyWorkspace {Blockly.WorkspaceSvg} -
 *
 * @return void
 */
const initFunc = (
  interpreter: Interpreter<DraftTemplateInputParameter[]>,
  globalObject: object,
  inputParameters: DraftTemplateInputParameter[],
  blocklyWorkspace?: Blockly.WorkspaceSvg,
): void => {
  // Save input parameters as a key valued object
  const inputMap = inputParameters.reduce<KeyValuedTemplateInputParameter>(
    (acc, curr) => ((acc[curr.name] = curr), acc),
    {},
  );

  //API function send the input parameters to JSInterpreter
  const input = interpreter.createNativeFunction(function () {
    return JSON.stringify(inputMap);
  });
  interpreter.setProperty(globalObject, codeRunnerGetInput, input);

  //API function to get the transformed input parameters
  const output = interpreter.createNativeFunction(function (text: string) {
    const result = JSON.parse(text);
    return Object.keys(result).map((key) => ({ ...result[key] }));
  });
  interpreter.setProperty(globalObject, codeRunnerSetOutput, output);

  // Add an API function for highlighting blocks.
  if (blocklyWorkspace) {
    const wrapperHighlight = function (id?: string) {
      id = String(id || '');
      return blocklyWorkspace.highlightBlock(id);
    };
    interpreter.setProperty(
      globalObject,
      'highlightBlock',
      interpreter.createNativeFunction(wrapperHighlight),
    );
  }
};

/**
 * Helper function to highlight faulty block in blockly
 * @param blocklyWorkspace {Blockly.WorkspaceSvg} - Blockly workspace
 * @param line {number} - Faulty Line number
 * @param linesOfCode {string[]} - Code split by line
 *
 * @return void
 *
 *  Since blockly injects blockId before each line of the generated JS code
 *  we could extract blockId from the line before error line.
 *  Example:
 *    highlightBlock(<BLOCK_ID>);
 *    <LINE JS CODE>;
 */
const highlightFaultyBlock = (
  blocklyWorkspace: Blockly.WorkspaceSvg,
  line: number,
  linesOfCode: string[],
): void => {
  const errorLineBlockId = extractBlockIdfromCode(linesOfCode[line - 2]);
  blocklyWorkspace.highlightBlock(errorLineBlockId);
};

export default codeRunner;
