import React from 'react';
import Dropdown from '../Dropdown/Dropdown';
import useMultiValueList from './useMultiValueList';

interface MultiValueListProps {
  items: string[] | number[];
  value: string | number | undefined;
  readOnly: boolean;
  onSelectionChange: (newValue: string | number) => void;
}

const MultiValueList: React.FC<MultiValueListProps> = ({
  items,
  value,
  readOnly,
  onSelectionChange,
}) => {
  const { dropdownItems, handleOnSelect, selectedDropdownItem } = useMultiValueList({
    onSelectionChange,
    items,
    value,
  });

  return (
    <Dropdown
      elements={dropdownItems}
      onSelect={handleOnSelect}
      selectedItem={selectedDropdownItem}
      disabled={readOnly}
    />
  );
};

export default MultiValueList;
