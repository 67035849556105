import { Button } from '@adsk/alloy-react';
import React from 'react';
import theme from '../../../Common/global/midTheme';
import { RightButton } from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { Wrapper } from './InsertProductBar.styles';

interface InsertProductBarProps {
  handleInsertRFA: () => void;
  isLoading: boolean;
  isPreviewLoading: boolean;
  isFormDataValid: boolean;
}

const InsertProductBar: React.FC<InsertProductBarProps> = ({
  handleInsertRFA,
  isLoading,
  isPreviewLoading,
  isFormDataValid,
}): JSX.Element => (
  <Wrapper>
    <RightButton
      width={theme.vars.narrowInputControl}
      variant={Button.VARIANTS.PRIMARY}
      onClick={handleInsertRFA}
      loading={isLoading}
      disabled={isPreviewLoading || !isFormDataValid}
    >
      {text.revitInsert}
    </RightButton>
  </Wrapper>
);

export default InsertProductBar;
