import styled from 'styled-components';
import { Control } from '@adsk/alloy-react-dropdown';

export const DropdownTitle = styled.h4`
  ${({ theme }) => theme.typography.bodyMedium}
  padding: 0;
  margin: 0;
`;

export const DropdownComponent = styled.div<{ width?: number }>`
  max-width: ${({ width, theme }) => width || theme.vars.wideInputControl}px;
  padding: 0;
`;

export const DropdownIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0px ${({ theme }) => `${theme.vars.marginBase}px`} 0px 0px;
`;

export const DropdownControl: typeof Control = styled(Control)`
  background-color: ${({ theme }) => theme.colors.white};
`;
