import { getAuthToken } from './auth';
import { HostApi } from '../interfaces/cefSharp';
import { ApiService } from '../services/api.service';
import { DynamicContentProduct } from '../interfaces/dynamicContent';
import { PostProductPayload, ProductsResponse } from '../interfaces/products';
import text from '../../Common/global/text/text.json';
declare let hostApi: HostApi;

export const getAllProducts = async (projectId: string): Promise<DynamicContentProduct[]> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }
  const apiService = new ApiService('', token);

  const dcApiUrl = await hostApi.getDcApiUrl();
  const getProductsPath = `${dcApiUrl}projects/${projectId}/products?limit=100`;

  const products: DynamicContentProduct[] = [];
  let nextUrl: string | undefined = getProductsPath;

  while (typeof nextUrl !== 'undefined') {
    const productsResponse: ProductsResponse = (await apiService.get(nextUrl)).data;
    const { pagination, results } = productsResponse;
    products.push(...results);
    nextUrl = pagination.nextUrl;
  }

  return products;
};

export const postProduct = async (
  projectId: string,
  productPayload: PostProductPayload,
): Promise<DynamicContentProduct> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const dcApiService = new ApiService(dcApiUrl, token);

  const postProductPath = `${dcApiUrl}projects/${projectId}/products`;
  const response = await dcApiService.post(postProductPath, productPayload);

  return response.data;
};

export const deleteProduct = async (projectId: string, productId: string): Promise<void> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const dcApiService = new ApiService(dcApiUrl, token);

  const deleteProductPath = `${dcApiUrl}projects/${projectId}/products/${productId}`;
  await dcApiService.delete(deleteProductPath);
};
