import { HostApi } from '../interfaces/cefSharp';
import { FileMetaData, CachedFileInfo } from '../interfaces/localCache';

declare let hostApi: HostApi;

/**
 * Retrieves the metadata for locally cached files.
 * @param key The key to get the data for.
 * @returns Cached file metadata.
 * @throws If the JSON returned by the host API cannot be parsed.
 */
export const getLocallyCached = async (key: string): Promise<CachedFileInfo[]> => {
  if (!hostApi.getLocallyCached) {
    throw Error('getLocallyCached is not implemented in host API');
  }

  try {
    const fileMetaData: CachedFileInfo[] = await hostApi.getLocallyCached(key);
    return fileMetaData;
  } catch {
    throw new Error(`Failed to deserialize file metadata data.`);
  }
};

/**
 * Adds file information to the local cache.
 * @param key The key to store the data under.
 * @param signedUrl Signed URL from which to download the file.
 * @param name The filename.
 * @param type The output type, e.g. "RFA".
 * @param metaData Additional meta data (optional).
 */
export const downloadFileToLocalCache = async (
  key: string,
  signedUrl: string,
  name: string,
  type: string,
  metaData?: FileMetaData,
): Promise<void> => {
  if (!hostApi.downloadFileToLocalCache) {
    throw Error('downloadFileToLocalCache is not implemented in host API');
  }

  if (!metaData) {
    return await hostApi.downloadFileToLocalCache(key, signedUrl, name, type, null);
  }

  const metaDataJson = JSON.stringify(metaData);

  return await hostApi.downloadFileToLocalCache(key, signedUrl, name, type, metaDataJson);
};

export const writeToCache = async (
  key: string,
  type: string,
  metaData: FileMetaData,
): Promise<void> => {
  const metaDataJson = JSON.stringify(metaData);
  return await hostApi.writeToCache(key, type, metaDataJson);
};
