import { Tree, TreeNode } from '@adsk/alloy-react-tree';
import { ExpandIcon } from '@adsk/alloy-react';
import styled, { css } from 'styled-components';

export const StyledTree = styled(Tree)`
  width: ${({ theme }) => `${theme.vars.midTreeWidth}px`};
  max-height: ${({ theme }) => `${theme.vars.midTreeMaxHeight}px`};
  ${({ theme }) => theme.typography.bodyMedium}
  border: 1px solid ${({ theme }) => theme.colors.charcoal300};
  background-color: ${({ theme }) => theme.colors.selectedTreeCellBackgroundColor};
  overflow-x: auto;
  overflow-y: auto;
`;

export const StyledLeafNode = styled(TreeNode)`
  cursor: pointer;
`;

export const treeBranchNodeHighlightCSS = css`
  :hover {
    background-color: ${({ theme }) => theme.colors.selectedTreeCellBackgroundColor};
  }
  box-shadow: inset
    ${({
      theme: {
        vars: { boxShadowBase },
        colors,
      },
    }) =>
      `0 ${boxShadowBase * -1}px ${boxShadowBase * 2}px ${boxShadowBase * -1}px ${
        colors.charcoal300
      }`};
`;

export const StyledBranchNode = styled(StyledLeafNode)`
  ${treeBranchNodeHighlightCSS}
`;

export const StyledExpandedIcon = styled(ExpandIcon)`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase / 2.5}px`};
`;

export const StyledTreeWrapper = styled.div`
  height: fit-content;
`;
