import { FileFilter } from '../interfaces/fileSystem';
import { HostApi } from '../interfaces/cefSharp';
import * as b64Buffer from 'base64-arraybuffer';
import text from '../../Common/global/text/text.json';
import logger from '../../Common/global/logger';

declare let hostApi: HostApi;

/**
 *
 * @returns selected folder path
 */
export const selectFolder = async (topFolder?: string): Promise<string> =>
  (await hostApi.selectFolder(topFolder ?? '')) ?? '';

/**
 * @returns selected files path
 */
export const selectFile = async (
  filter: FileFilter[],
  multiSelect?: boolean,
  topFolder?: string,
): Promise<string[]> => {
  const fileFilter = JSON.stringify(filter);
  const filePaths = await hostApi.selectFile(topFolder ?? '', fileFilter, multiSelect ?? false);
  return filePaths;
};

/**
 * @param filePath The path to the file on the local filesystem
 * @returns an encoded base64 string representation of the file
 */
export const fileToBase64String = async (
  filePath: string,
): Promise<{ name: string; base64: string }> => {
  if (!hostApi.fileToBase64String) {
    throw Error('fileToBase64String is not implemented in hostApi');
  }
  const result = await hostApi.fileToBase64String(filePath);
  if (result.value === null) {
    throw new Error(`Failed to read file ${filePath}. Message: ${result.errorMessage}`);
  }
  return result.value;
};

export const toFileObject = async (file: string): Promise<File> => {
  const base64 = await fileToBase64String(file);

  return new File([b64Buffer.decode(base64.base64)], base64.name);
};

export const compressFolder = async (folderPath: string): Promise<string> => {
  const result = await hostApi.compressFolder(folderPath);
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const extractZipFileToFolder = async (
  zipFilePath: string,
  targetFolderPath: string,
): Promise<string> => {
  const result = await hostApi.extractZipFileToFolder(zipFilePath, targetFolderPath);

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const downloadFileFromUrl = async (url: string): Promise<string> => {
  const result = await hostApi.downloadFileFromUrl(url);
  if (result.value === null) {
    throw new Error(`${text.failedToDownloadFile} ${url}. Message: ${result.errorMessage}`);
  }
  return result.value;
};

export const urlContentToBase64String = async (
  url: string,
): Promise<{ name: string; base64: string }> => {
  if (!hostApi.downloadFileFromUrl) {
    throw Error('downloadFileFromUrl is not implemented in hostApi');
  }

  if (!hostApi.fileToBase64String) {
    throw Error('fileToBase64String is not implemented in hostApi');
  }

  const downloadResult = await hostApi.downloadFileFromUrl(url);
  if (downloadResult.value === null) {
    throw new Error(`Failed to download file from url. Message: ${downloadResult.errorMessage}`);
  }
  const result = await hostApi.fileToBase64String(downloadResult.value);
  if (result.value === null) {
    throw new Error(`Failed to change file to base64. Message: ${result.errorMessage}`);
  }
  return result.value;
};

export const deleteFile = async (filePath: string): Promise<boolean> => {
  const result = await hostApi.deleteFile(filePath);
  if (!result.value) {
    logger.error(`${result.errorMessage}`);
    return false;
  }
  return true;
};

export const checkAndGenerateThumnailInBase64 = async (
  filePath: string,
  documentPath: string,
): Promise<{ name: string; base64: string }> => {
  if (!hostApi.checkAndGenerateThumnailInBase64) {
    throw Error('checkAndGenerateThumnailInBase64 is not implemented in hostApi');
  }
  const result = await hostApi.checkAndGenerateThumnailInBase64(filePath, documentPath);
  if (result.value === null) {
    throw new Error(`Failed to read file ${filePath}. Message: ${result.errorMessage}`);
  }
  return result.value;
};
