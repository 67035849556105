import { TREE_ACTIONS } from '@adsk/alloy-react-tree';
import { BaseAction } from '@adsk/alloy-react-tree/es/hooks/useTreeReducer';
import { IdType } from '@adsk/alloy-react-tree/es/types';
import { TreeItem } from '../MIDTree.types';
import { AddChildPayloadType } from './AsyncMIDTreeNode.types';

export const addChildNodes = (
  nodeId: IdType,
  newChildNodes: TreeItem[],
  dispatch: React.Dispatch<BaseAction<TreeItem>>,
): void => {
  const addChildPayload: AddChildPayloadType = {
    targetId: nodeId,
    newChildNodes,
  };
  dispatch({
    type: TREE_ACTIONS.addChildNodes,
    payload: addChildPayload,
  });
};
