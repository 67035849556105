import { Button } from '@adsk/alloy-react';
import React from 'react';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';
import {
  LeftSection,
  RightSection,
  Subtitle,
} from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';

interface CustomizeProductHeaderProps {
  handleChangeProductClick: () => void;
}

const CustomizeProduct: React.FC<CustomizeProductHeaderProps> = ({
  handleChangeProductClick,
}): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.revitProductCustomization}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton
        data-testid="change-product-button"
        variant={Button.VARIANTS.SECONDARY}
        onClick={handleChangeProductClick}
        size={Button.SIZES.SMALL}
      >
        {text.revitChangeProduct}
      </RightButton>
    </RightSection>
  </>
);

export default CustomizeProduct;
