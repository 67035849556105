import { TemplateInputType } from '../../../lib/interfaces/dynamicContent';
import { AvailableInputFunctionValues } from './BlocklyModule.types';
import Blockly from 'blockly';

export enum customBlocklyExtensions {
  INPUT_DROPDOWN_EXTENSION = 'INPUT_DROPDOWN_EXTENSION',
}

export enum customBlocklyMutators {
  INPUT_DROPDOWN_MUTATOR = 'INPUT_DROPDOWN_MUTATOR',
}

export const blocklyDropdown = 'dropdown';
export const blocklyFunctionsDropdown = 'functions_dropdown';
export const blocklyToolboxInputsCategory = 'DCD_INPUTS';
export const blocklyConnectingBlock = 'connecting_block';

export const universalInputBlock = 'universal_input_block';
export const universalOutputBlock = 'universal_output_block';

export const codeRunnerGetInput = 'getInput';
export const codeRunnerSetOutput = 'setOutput';

// Available Keys from dynamic content in Blockly
export const valueKey: AvailableInputFunctionValues = 'value';
export const visibleKey: AvailableInputFunctionValues = 'visible';
export const readOnlyKey: AvailableInputFunctionValues = 'readOnly';
export const labelKey: AvailableInputFunctionValues = 'label';
export const trueLabelKey: AvailableInputFunctionValues = 'trueLabel';
export const falseLabelKey: AvailableInputFunctionValues = 'falseLabel';
export const minKey: AvailableInputFunctionValues = 'min';
export const maxKey: AvailableInputFunctionValues = 'max';
export const incrementKey: AvailableInputFunctionValues = 'increment';
export const valuesKey: AvailableInputFunctionValues = 'values';

// All available keys for the universal input/output blocks
export const availableFunctionValuesArray = [
  valueKey,
  visibleKey,
  readOnlyKey,
  labelKey,
  trueLabelKey,
  falseLabelKey,
  minKey,
  maxKey,
  incrementKey,
  valuesKey,
] as const;

const availableFunctionsMap = new Map<string, AvailableInputFunctionValues>(
  Object.values(availableFunctionValuesArray).map((v) => [v, v]),
);

// helper to check if key for function is available
export function isfunctionAvailable(value: string): value is AvailableInputFunctionValues {
  return availableFunctionsMap.get(value) !== undefined;
}

export const blocklyBlockOptionsByType: {
  [key in TemplateInputType]: [AvailableInputFunctionValues, AvailableInputFunctionValues][];
} = {
  [TemplateInputType.Boolean]: [
    [valueKey, valueKey],
    [trueLabelKey, trueLabelKey],
    [falseLabelKey, falseLabelKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
  [TemplateInputType.Numeric]: [
    [valueKey, valueKey],
    [minKey, minKey],
    [maxKey, maxKey],
    [incrementKey, incrementKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
  [TemplateInputType.MultiValueNumeric]: [
    [valueKey, valueKey],
    [valuesKey, valuesKey],
    [minKey, minKey],
    [maxKey, maxKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
  [TemplateInputType.MultiValueText]: [
    [valueKey, valueKey],
    [valuesKey, valuesKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
  [TemplateInputType.IProperty]: [
    [valueKey, valueKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
  [TemplateInputType.Text]: [
    [valueKey, valueKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [labelKey, labelKey],
  ],
};

export const blockTypeByInputType: { [key in TemplateInputType]: string } = {
  [TemplateInputType.Boolean]: 'Boolean',
  [TemplateInputType.Numeric]: 'Number',
  [TemplateInputType.MultiValueNumeric]: 'Number',
  [TemplateInputType.MultiValueText]: 'String',
  [TemplateInputType.IProperty]: 'String',
  [TemplateInputType.Text]: 'String',
};

export const blockTypeByFunctionType: {
  [key in AvailableInputFunctionValues]: string;
} = {
  [valueKey]: 'String',
  [visibleKey]: 'Boolean',
  [readOnlyKey]: 'Boolean',
  [labelKey]: 'String',
  [trueLabelKey]: 'String',
  [falseLabelKey]: 'String',
  [minKey]: 'Number',
  [maxKey]: 'Number',
  [incrementKey]: 'Number',
  [valuesKey]: 'Array',
};

export const BLOCKLY_EVENTS_TO_UPDATE = [
  Blockly.Events.BLOCK_CHANGE,
  Blockly.Events.BLOCK_CREATE,
  Blockly.Events.BLOCK_DELETE,
  Blockly.Events.BLOCK_MOVE,
];
