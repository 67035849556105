import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  height: ${({ theme }) => `${theme.vars.headersHeight}px`};
`;

export const TopHeader = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 1.5}px ${theme.vars.paddingBase * 2}px`};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.charcoal100};
`;

export const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.typography.heading2}
  line-height: 24px !important;
`;

export const Subtitle = styled.h2`
  margin: 0;
  ${({ theme }) => theme.typography.heading3}
  line-height: 24px !important;
`;

export const LeftSection = styled.div`
  flex: 1 0 auto;
`;

export const RightSection = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-end;
  align-items: center;
`;
