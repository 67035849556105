'use strict';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Environment, ServiceConfigMap, ServiceTypes } from './config';

export interface GetRequestOptions {
  fetchBlob?: boolean;
}

export type ApiServiceResponse = Promise<AxiosResponse<any>>;
export type ApiServiceAbortableResponse = { abort: () => void; response: ApiServiceResponse };

export class ApiService {
  private axios: AxiosInstance;

  constructor(baseURL: string, token = '') {
    const axiosConfig: AxiosRequestConfig = {
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    // create new axios instance
    this.axios = axios.create(axiosConfig);
    // setup interceptors for axios instance
    this.axios.interceptors.response.use((response) => response, handleAxiosResponseError);
  }

  public get(path: string, config?: GetRequestOptions): ApiServiceResponse {
    // create request-scoped config
    const requestConfig: AxiosRequestConfig = config?.fetchBlob ? { responseType: 'blob' } : {};
    // call get method request-scoped config
    return this.axios.get(path, requestConfig);
  }

  public post(path: string, data?: any): ApiServiceResponse {
    return this.axios.post(path, data);
  }

  public delete(path: string): ApiServiceResponse {
    return this.axios.delete(path);
  }
}

const handleAxiosResponseError = (err: AxiosError) => {
  console.error('== Response Error : ', err);

  return Promise.reject(err);
};

export class ApiServiceFactory {
  public static createApiService(
    serviceType: ServiceTypes,
    options: { token: string; env: Environment },
  ): ApiService {
    const { env, token } = options;
    const service = ServiceConfigMap[serviceType][env];
    if (!service || !service.api) {
      throw new Error('Please provide a valid API URL.');
    }
    return new ApiService(service.api, token);
  }
}
