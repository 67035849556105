import { DropdownItem } from '../Dropdown/Dropdown.types';

export interface UseMultiValueListArgs {
  items: string[] | number[];
  onSelectionChange: (newValue: string | number) => void;
  value: string | number | undefined;
}
export interface UseMultiValueListState {
  selectedDropdownItem: DropdownItem | null;
  dropdownItems: DropdownItem[];
  handleOnSelect: (item: { selectedItem?: DropdownItem | null }) => void;
}

const useMultiValueList = ({
  items,
  value,
  onSelectionChange,
}: UseMultiValueListArgs): UseMultiValueListState => {
  const dropdownItems: DropdownItem[] = items.map((item) => ({
    value: item,
    label: item,
  }));
  const initialLocalSelectedItem = dropdownItems.length > 0 ? dropdownItems[0] : null;
  const selectedDropdownItem =
    dropdownItems.find((dropdownItem) => value === dropdownItem.value) || initialLocalSelectedItem;

  const handleOnSelect = (item: { selectedItem?: DropdownItem | null }): void => {
    if (item.selectedItem) {
      onSelectionChange(item.selectedItem.value);
    } else {
      throw new Error('Invalid selectedItem found.');
    }
  };

  return {
    selectedDropdownItem,
    handleOnSelect,
    dropdownItems,
  };
};

export default useMultiValueList;
