import { TextInput, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import { TooltipIcon, TooltipWrapper } from '../../global/styles/Common/Common.styles';
import { NumericTextInputWrapper } from './NumericParameterCustomization.styles';
import { useNumericParameterCustomization } from './useNumericParameterCustomization';

interface NumericParameterCustomizationProps {
  value: number;
  unit: string;
  readOnly: boolean;
  min?: number;
  max?: number;
  increment?: number;
  onNumericValueChange: (newValue: number) => void;
  setIsFormDataValid?: (isFormValid: boolean) => void;
}

const NumericParameterCustomization: React.FC<NumericParameterCustomizationProps> = ({
  value,
  unit,
  min,
  max,
  increment,
  readOnly,
  onNumericValueChange,
  setIsFormDataValid,
}) => {
  const {
    numericValue,
    handleInputChange,
    handleOnBlur,
    handleKeyDown,
    tooltipContent,
    tooltipIconType,
    isNumericValueValid,
  } = useNumericParameterCustomization({
    value,
    unit,
    min,
    max,
    increment,
    readOnly,
    onNumericValueChange,
    setIsFormDataValid,
  });

  return (
    <NumericTextInputWrapper>
      <TextInput
        type="number"
        value={numericValue?.toString()}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        max={max}
        min={min}
        step={increment}
        readOnly={readOnly}
        error={!isNumericValueValid}
      />
      {tooltipContent && (
        <TooltipWrapper>
          <Tooltip content={tooltipContent}>
            <TooltipIcon type={tooltipIconType} size={17} fr={0} />
          </Tooltip>
        </TooltipWrapper>
      )}
    </NumericTextInputWrapper>
  );
};

export default NumericParameterCustomization;
