import { ILLUSTRATION_TYPES } from '@adsk/alloy-react';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import React from 'react';
import MIDEmptyState from '../../Common/components/EmptyState/MIDEmptyState';
import { Title } from '../../Common/global/styles/Header/Header.styles';
import text from '../../Common/global/text/text.json';
import InsertProductBar from '../components/InsertProductBar/InsertProductBar';
import ProductCustomizationForm from '../../Common/components/ProductCustomization/ProductCustomizationForm';
import ProductCustomizationHeader from '../../Common/components/ProductCustomization/ProductCustomizationHeader';
import { CustomizationWrapper } from '../../Common/components/ProductCustomization/ProductCustomization.styles';
import {
  CustomizeContainer,
  PreviewContainer,
  PreviewImage,
  PreviewInProgressMessageWrap,
  PreviewLoader,
  PreviewLoadingTimeMessageWrap,
  PreviewWrapper,
  Wrapper,
} from './Screens.styles';
import { useProductCustomizationScreen } from './useProductCustomizationScreen';
import { ProductDetails } from '../components/ProductDetails/ProductDetails';

export const ProductCustomizationScreen: React.FC = (): JSX.Element => {
  const {
    isPreviewLoading,
    isRFAGenerationLoading,
    shouldThumbnailBoxShowLoader,
    inputs,
    productRFAOutput,
    thumbnailInBase64,
    thumbnailError,
    isFormDataValid,
    setIsFormDataValid,
    handleInsertRFA,
    handleResetClick,
    handleUpdatePreviewClick,
    handleInputUpdate,
  } = useProductCustomizationScreen();

  return (
    <>
      <Wrapper>
        <CustomizeContainer>
          <ProductCustomizationHeader
            isPreviewLoading={isPreviewLoading}
            isFormDataValid={isFormDataValid}
            isInsertLoading={isRFAGenerationLoading}
            handleUpdatePreviewClick={handleUpdatePreviewClick}
            handleResetClick={handleResetClick}
          />
          <ProductCustomizationForm
            inputs={inputs}
            handleInputUpdate={handleInputUpdate}
            setIsFormDataValid={setIsFormDataValid}
          />
        </CustomizeContainer>
        <PreviewContainer>
          <CustomizationWrapper>
            <Title>{text.revitPreview}</Title>
          </CustomizationWrapper>
          <PreviewWrapper>
            {shouldThumbnailBoxShowLoader ? (
              <PreviewLoader>
                <ProgressRing size={ProgressRing.SIZES.LARGE} />
                <PreviewInProgressMessageWrap>
                  {text.revitUpdateInProgressMessage}
                </PreviewInProgressMessageWrap>
                <PreviewLoadingTimeMessageWrap>
                  {text.revitLoadingTimeMessage}
                </PreviewLoadingTimeMessageWrap>
              </PreviewLoader>
            ) : thumbnailInBase64 ? (
              <PreviewImage
                src={`data:image/jpeg;base64,${thumbnailInBase64}`}
                alt={text.previewImageAltText}
              />
            ) : (
              <MIDEmptyState
                illustrationType={
                  thumbnailError ? ILLUSTRATION_TYPES.SYSTEM_ERROR : ILLUSTRATION_TYPES.PAGE_PHOTO
                }
                title={thumbnailError ? text.revitPreviewNotAvailable : ''}
              />
            )}
          </PreviewWrapper>
          <ProductDetails
            title={text.revitProductDetails}
            productCategory={productRFAOutput?.options?.category || ''}
            productFamily={productRFAOutput?.options?.family || ''}
            productLevelOfDetail={productRFAOutput?.options?.modelStates?.[0] || ''}
          />
        </PreviewContainer>
      </Wrapper>
      <InsertProductBar
        handleInsertRFA={handleInsertRFA}
        isLoading={isRFAGenerationLoading}
        isPreviewLoading={isPreviewLoading}
        isFormDataValid={isFormDataValid}
      />
    </>
  );
};
