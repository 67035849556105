import React from 'react';
import { CheckboxState } from '@adsk/alloy-react';
import { DefaultInputTreeNode, IdType, InternalDataNode } from '@adsk/alloy-react-tree/es/types';
import { StyledTree, StyledTreeWrapper } from './MIDTree.styles';
import { useTree } from '@adsk/alloy-react-tree';
import AsyncMIDTreeNode from './TreeNodes/AsyncMIDTreeNode';
import { MetaInfo } from '../../../lib/interfaces/templates';
import { TreeItem } from './MIDTree.types';
import { GetChildNodes } from './TreeNodes/AsyncMIDTreeNode.types';
import useMIDTree from './useMIDTree';

interface MIDTreeProps {
  treeRootElements: TreeItem[];
  selectedTreeElement: TreeItem | undefined;
  onSelect: (item: TreeItem, path: MetaInfo[]) => void;
  getChildNodes: GetChildNodes;
}

const MIDTree: React.FC<MIDTreeProps> = ({
  treeRootElements,
  selectedTreeElement,
  onSelect,
  getChildNodes,
}) => {
  const { orderedIds, normalizedTree, getTreeNodeProps, getTreeProps, dispatch } = useTree({
    selectedIds: selectedTreeElement?.id ? { [selectedTreeElement.id]: true } : {},
    onSelect: (element: { isSelected: CheckboxState; id: IdType }) => {
      const selectedItem = normalizedTree[element.id];
      const parentPath: MetaInfo[] = selectedItem.path.map((path) => {
        const parentElement: InternalDataNode<DefaultInputTreeNode> = normalizedTree[path];
        return { id: parentElement.id.toString(), name: parentElement.label };
      });

      onSelect(selectedItem.original as TreeItem, parentPath);
    },
    denormalizedTree: treeRootElements,
  });
  const { filterNewChildNodes } = useMIDTree({ dispatch, treeRootElements, orderedIds });

  return (
    <StyledTreeWrapper>
      <StyledTree {...getTreeProps()}>
        {orderedIds.map((id) => {
          const treeNode = normalizedTree[id];
          const originalItem = treeNode.original as TreeItem;
          const treeNodeProps = getTreeNodeProps(treeNode);
          return (
            <AsyncMIDTreeNode
              {...treeNodeProps}
              dispatch={dispatch}
              key={treeNodeProps.id}
              selectedItem={originalItem}
              getChildNodes={getChildNodes}
              isExpandable={!!originalItem.isExpandable}
              iconType={originalItem.iconType}
              filterNewChildNodes={filterNewChildNodes}
            />
          );
        })}
      </StyledTree>
    </StyledTreeWrapper>
  );
};

export default MIDTree;
