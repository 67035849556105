import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react-notification';
import { useEffect, useState } from 'react';
import { BIM360Account, BIM360Project } from '../../../lib/interfaces/forge-api';
import { MetaInfo } from '../../../lib/interfaces/templates';
import { getAccAccounts, getProjects } from '../../../lib/utils/workspace';
import { useAsyncFetchData, useAsyncFetchDataWithArgs } from '../../global/hooks/http/hooks';
import text from '../../global/text/text.json';
import { DropdownItem } from '../Dropdown/Dropdown.types';
import { SELECTED_ACCOUNT_ID, SELECTED_PROJECT_ID } from './constants';

export interface UseACCDocSelectionState {
  accounts: DropdownItem[] | undefined;
  accountsLoading: boolean;
  projects: DropdownItem[] | undefined;
  projectsLoading: boolean;
  projectsDisabled: boolean;
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  handleSelectAccount: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleSelectProject: (changes: { selectedItem?: DropdownItem | null }) => void;
}

export const useACCDocSelection = (): UseACCDocSelectionState => {
  const [selectedAccount, setSelectedAccount] = useState<MetaInfo | undefined>();
  const [selectedAccountIdDependencyList, setSelectedAccountIdDependencyList] =
    useState<string[]>();
  const [selectedProject, setSelectedProject] = useState<MetaInfo | undefined>();
  const [projectsDisabled, setProjectsDisabled] = useState(true);
  const { showNotification } = useNotifications();

  const {
    data: accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useAsyncFetchData<BIM360Account[]>(getAccAccounts);
  useEffect(() => {
    if (accountsError) {
      showNotification({
        message: text.notificationGetAccountsFailed,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [accountsError, showNotification]);

  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useAsyncFetchDataWithArgs<BIM360Project[]>(getProjects, selectedAccountIdDependencyList);
  useEffect(() => {
    if (projectsError) {
      showNotification({
        message: text.notificationGetProjectsFailed,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [projectsError, showNotification]);

  useEffect(() => {
    if (selectedAccount?.id) {
      setProjectsDisabled(false);
      setSelectedProject(undefined);
    }
  }, [selectedAccount?.id]);

  useEffect(() => {
    const selectedAccountIdInSession = sessionStorage.getItem(SELECTED_ACCOUNT_ID);
    if (selectedAccountIdInSession && accounts !== null && accounts.length > 0) {
      const selectedAccount = accounts.find((acc) => acc.id === selectedAccountIdInSession);
      if (selectedAccount) {
        setSelectedAccount({ id: selectedAccount.id, name: selectedAccount.name });
      } else {
        sessionStorage.removeItem(SELECTED_ACCOUNT_ID);
      }
      setSelectedAccountIdDependencyList([selectedAccountIdInSession]);
    }
  }, [accounts]);

  useEffect(() => {
    const selectedProjectIdInSession = sessionStorage.getItem(SELECTED_PROJECT_ID);
    if (selectedProjectIdInSession && projects !== null && projects.length > 0) {
      const selectedProject = projects.find((prj) => prj.id === selectedProjectIdInSession);
      if (selectedProject) {
        setSelectedProject({ id: selectedProject.id, name: selectedProject.name });
      } else {
        sessionStorage.removeItem(SELECTED_PROJECT_ID);
      }
    }
  }, [projects]);

  const handleSelectAccount = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem?.id) {
      const { id, value } = selectedItem;
      setSelectedAccount({
        id,
        name: value.toString(),
      });
      setSelectedAccountIdDependencyList([id]);
      sessionStorage.setItem(SELECTED_ACCOUNT_ID, id);
    }
  };

  const handleSelectProject = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem?.id) {
      const { id, value } = selectedItem;
      setSelectedProject({
        id,
        name: value.toString(),
      });
      sessionStorage.setItem(SELECTED_PROJECT_ID, id);
    }
  };

  const _transformInDropdownItems = (
    list: BIM360Account[] | BIM360Project[] | null,
  ): DropdownItem[] | undefined => {
    if (list && list.length) {
      return list.map((accountOrProject) => ({
        id: accountOrProject.id,
        value: accountOrProject.name,
        label: accountOrProject.name,
      }));
    }
  };

  return {
    accounts: _transformInDropdownItems(accounts),
    accountsLoading,
    projects: _transformInDropdownItems(projects),
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  };
};
