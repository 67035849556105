import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CustomizeContainer = styled.div`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  min-width: ${({ theme }) => `${theme.vars.wideInputControl}px`};
  width: 50%;
`;

export const PreviewContainer = styled.div`
  ${({ theme }) => theme.typography.bodyMedium};
  padding-bottom: ${({ theme }) => `${theme.vars.paddingBase}px`};
  margin: 0 ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  width: 50%;
  height: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.footerHeight}px`}
  );
  overflow: auto;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  min-height: 250px;
  max-height: 300px;
`;

export const PreviewLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const PreviewInProgressMessageWrap = styled.p`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

export const PreviewLoadingTimeMessageWrap = styled.p`
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

export const PreviewImage = styled.img`
  height: 100%;
  width: auto;
`;
