import { ModalConfirmation } from '@adsk/alloy-react';
import styled from 'styled-components';
import { Label } from '../../global/styles/Common/Common.styles';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.vars.marginBase * 3}px;
`;

export const StyledModal = styled(ModalConfirmation)`
  align-items: center;
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const CheckboxLabel = styled(Label)`
  display: flex;
  align-self: flex-start;
`;

export const StyledSpan = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.vars.marginBase}px;
`;
