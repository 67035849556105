import styled from 'styled-components';

export const CustomizationWrapper = styled.div`
  height: ${({ theme }) => `${theme.vars.largeButtonHeight}px`};
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const NumericTextInputWrapper = styled.div`
  display: flex;
  width: ${({ theme }) => `${theme.vars.narrowInputControl}px`};
`;

export const CustomizationFormWrapper = styled.form`
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.largeButtonHeight}px`} -
      ${({ theme }) => `${theme.vars.footerHeight}px`}
  );
  overflow: auto;
`;

export const CustomizationModalHeader = styled.h3`
  margin: 0;
`;
