import { useEffect, useReducer, useState } from 'react';
import { StoreSetterType } from '../../../Common/context/context.types';
import { DynamicContentInput, DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { ConfigurableProductActions, ConfigurableProductActionTypes } from './dataStore.actions';
import { configurableProductReducer } from './dataStore.reducers';
import { ConfigurableProductProperties, ConfigurableProductPropertiesMap } from './dataStore.types';

export const initialProductTemplate: DynamicContentProduct = {
  contentId: '',
  thumbnail: '',
  context: {
    topLevelAssembly: '',
    engine: {
      location: '',
      version: '',
    },
    projectFile: '',
    workspace: {
      folderPath: '',
      location: '',
    },
  },
  dataSetLocation: '',
  inputs: [],
  name: '',
  outputs: [],
  rules: {},
  codeBlocksWorkspace: '',
  schemaVersion: 1,
  tenancyId: '',
};

export const initialConfigurableProductPropertiesMap: ConfigurableProductPropertiesMap = {
  inputs: new Map(),
  // TODO: if/when we integrate outputs changes, the outputs below should be changed to a Map
  outputs: [],
};

const _initializeConfigurableProductProperties = (
  currentProduct: DynamicContentProduct,
  dispatchConfigurableProductUpdateAction: React.Dispatch<ConfigurableProductActions>,
) => {
  dispatchConfigurableProductUpdateAction({
    type: ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD,
    payload: {
      inputs: currentProduct.inputs,
      outputs: currentProduct.outputs,
    },
  });
};

export interface DataStore {
  currentProduct: DynamicContentProduct;
  setCurrentProduct: StoreSetterType<DynamicContentProduct>;
  configurableProductProperties: ConfigurableProductProperties;
  updateConfigurableProductInput: (inputToUpdate: DynamicContentInput) => void;
  resetConfigurableProductProperties: () => void;
  updateConfigurableProductInputWithCodeRunner: (inputToUpdate: DynamicContentInput[]) => void;
}

export const useStore = (): DataStore => {
  const [currentProduct, setCurrentProduct] =
    useState<DynamicContentProduct>(initialProductTemplate);
  const [configurableProductPropertiesMap, dispatchConfigurableProductUpdateAction] = useReducer(
    configurableProductReducer,
    initialConfigurableProductPropertiesMap,
  );

  useEffect(() => {
    _initializeConfigurableProductProperties(
      currentProduct,
      dispatchConfigurableProductUpdateAction,
    );
  }, [currentProduct]);

  const updateConfigurableProductInput = (inputToUpdate: DynamicContentInput) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_INPUT,
      payload: inputToUpdate,
    });
  };

  const updateConfigurableProductInputWithCodeRunner = (updatedInputs: DynamicContentInput[]) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_INPUT_WITH_CODE_RUNNER,
      payload: updatedInputs,
    });
  };

  // TODO: if/when we integrate outputs changes, implements a setConfigurableProductOutput method
  const resetConfigurableProductProperties = () =>
    _initializeConfigurableProductProperties(
      currentProduct,
      dispatchConfigurableProductUpdateAction,
    );

  return {
    currentProduct,
    setCurrentProduct,
    configurableProductProperties: {
      inputs: [...configurableProductPropertiesMap.inputs.values()],
      outputs: configurableProductPropertiesMap.outputs,
    },
    updateConfigurableProductInput,
    resetConfigurableProductProperties,
    updateConfigurableProductInputWithCodeRunner,
  };
};
