import { HostApi } from '../interfaces/cefSharp';
import {
  DynamicContentInput,
  DynamicContentVariant,
  DynamicContentVariantOutput,
  TemplateContext,
  TemplateRule,
  VariantOutputStatus,
  VariantOutputType,
} from '../interfaces/dynamicContent';
import {
  CachedDynamicContentVariant,
  CachedVariantMetaInfo,
  NameValuePair,
  PostVariantPayload,
} from '../interfaces/variants';
import { ApiService } from '../services/api.service';
import { getAuthToken } from './auth';
import objectHash from 'object-hash';
import { downloadFileToLocalCache, getLocallyCached, writeToCache } from './localCache';
import { getDownloadUrl } from './cloudStorage';
import { FileMetaData } from '../interfaces/localCache';
import logger from '../../Common/global/logger';
import text from '../../Common/global/text/text.json';

declare let hostApi: HostApi;

export const getVariantFromAPI = async (
  projectId: string,
  productId: string,
  variantId: string,
): Promise<DynamicContentVariant> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const getVariantPath = `projects/${projectId}/products/${productId}/variants/${variantId}`;

  const apiService = new ApiService(dcApiUrl, token);
  const response = await apiService.get(getVariantPath);
  return response.data;
};

export const postVariantToAPI = async (
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload,
): Promise<DynamicContentVariant> => {
  const token = await getAuthToken();
  if (!token) {
    throw Error(text.unauthorizedAccessMessage);
  }

  const dcApiUrl = await hostApi.getDcApiUrl();
  const postVariantPath = `projects/${projectId}/products/${productId}/variants`;

  const apiService = new ApiService(dcApiUrl, token);
  const response = await apiService.post(postVariantPath, variantPayload);
  return response.data;
};

const translateToCachedVariantMetaInfo = (
  variant: DynamicContentVariant,
): CachedVariantMetaInfo => ({
  name: variant.name,
  context: JSON.stringify(variant.context),
  dataSetLocation: variant.dataSetLocation,
  rules: JSON.stringify(variant.rules),
  schemaVersion: `${variant.schemaVersion}`,
  inputs: JSON.stringify(variant.inputs),
});

export const getVariantOutputs = async (
  projectId: string,
  productId: string,
  variantId: string,
): Promise<DynamicContentVariantOutput[]> => {
  try {
    const variantOutput: DynamicContentVariantOutput[] = [];

    let cached = await getLocallyCached(variantId);

    if (!cached || cached.length === 0) {
      // not found in cache, retrieve from API
      const variant = await getVariantFromAPI(projectId, productId, variantId);

      if (variant.outputs.some((o) => !o.urn)) {
        // only cache variant if all ouputs were successful
        return variant.outputs;
      }

      // cache meta
      const metaInfo = { ...translateToCachedVariantMetaInfo(variant) } as FileMetaData;

      await writeToCache(variantId, 'VARIANTINFO', metaInfo);

      await Promise.all(
        variant.outputs.map(async (o) => {
          if (!o.urn) {
            return Promise.resolve();
          }

          // cache output
          // write(key, url, type)
          const outputData = {} as FileMetaData;
          if (o.category) {
            outputData['category'] = o.category;
          }
          if (o.family) {
            outputData['family'] = o.family;
          }
          if (o.modelState) {
            outputData['modelState'] = o.modelState;
          }

          const url = await getDownloadUrl(variant.tenancyId, o.urn);
          const name = o.type === 'RFA' ? `${variant.name}.rfa` : o.type;
          await downloadFileToLocalCache(variant.variantId, url, name, o.type, outputData);

          return Promise.resolve();
        }),
      );

      cached = await getLocallyCached(variantId);
    }

    const cachedOutputs = cached?.filter(
      (c) => Object.values<string>(VariantOutputType).indexOf(c.type) >= 0,
    );
    if (cached && cachedOutputs.length > 0) {
      variantOutput.push(
        ...cachedOutputs.map(
          (c) =>
            ({
              type: c.type,
              status: VariantOutputStatus.SUCCESS,
              urn: c.filePath,
              modelState: c['modelState'],
              family: c['family'],
              category: c['category'],
            } as DynamicContentVariantOutput),
        ),
      );
    }
    return variantOutput;
  } catch {
    // do nothing
    return [];
  }
};

export const postVariant = async (
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload,
): Promise<DynamicContentVariant> => {
  try {
    // check if variant already cached
    const variantId = calculateVariantHash(projectId, productId, 1, variantPayload.inputs);
    const cached = await getLocallyCached(variantId);

    if (
      cached &&
      cached.length > 0 &&
      variantPayload.outputs.every((output) => cached.find((c) => c.type === output.type))
    ) {
      const cachedOutputs = cached?.filter(
        (c) => Object.values<string>(VariantOutputType).indexOf(c.type) >= 0,
      );

      const cachedMetaInfo = cached?.find((c) => c.type === 'VARIANTINFO');
      const thumbnail =
        cachedOutputs.find((o) => o.type === VariantOutputType.THUMBNAIL)?.filePath ?? '';
      // re-create variant
      const dcv: CachedDynamicContentVariant = {
        isCached: true,
        tenancyId: projectId,
        contentId: productId,
        variantId,
        context: JSON.parse(cachedMetaInfo?.context ?? '{}') as TemplateContext,
        name: cachedMetaInfo?.name ?? '',
        dataSetLocation: cachedMetaInfo?.dataSetLocation ?? '',
        rules: JSON.parse(cachedMetaInfo?.rules ?? '{}') as TemplateRule,
        inputs: variantPayload.inputs as DynamicContentInput[],
        thumbnail,
        schemaVersion: Number(cachedMetaInfo?.schemaVersion ?? '1'),
        outputs: cachedOutputs.map((o) => ({
          type: o.type,
          status: VariantOutputStatus.SUCCESS,
          urn: o.filePath,
          modelState: o['modelState'],
          category: o['category'],
          family: o['family'],
        })),
      };

      return dcv;
    }
  } catch (err) {
    // local cache retrieval failed
    logger.error(text.failRetrieveLocalCacheError, { err });
    throw err;
  }
  return postVariantToAPI(projectId, productId, variantPayload);
};

/**
 * Converts a hex string to a base32 string
 * @param hexString hex (base16) string
 * @returns base32 encoded string
 */
export const hexToBase32 = (hexString: string): string => {
  let hash = '';
  for (let i = 0; i < hexString.length; i += 5) {
    // read hexString in chunks of 5 characters (or rest)
    const hexSlice = hexString.substring(i, Math.min(i + 5, hexString.length));
    // convert hex string to base32 string
    let b32Slice = parseInt(hexSlice, 16).toString(32);

    // ensure to not lose leading zeros
    const desiredLength = hexSlice.length === 5 ? 4 : hexSlice.length;
    while (b32Slice.length < desiredLength) {
      b32Slice = '0' + b32Slice;
    }
    // append to hash
    hash += b32Slice;
  }
  return hash;
};

/**
 * calculates deterministic hash of a variant based on referenced product and variant parameters
 * @param contentId id of referenced product
 * @param productVersion version of referenced product
 * @param parameters list of parameters (name-value pairs)
 * @returns deterministic hash
 */
export const calculateVariantHash = <T extends NameValuePair>(
  tenancyId: string,
  contentId: string,
  productVersion: number,
  parameters: T[],
): string => {
  // collect parameters in key value map
  const parametersMap: Record<string, any> = {};
  parameters.forEach(({ name, value }) => {
    parametersMap[name] = value;
  });

  // collect variant identifying data in an object
  const objectToHash = {
    projectId: tenancyId,
    productId: contentId,
    productVersion,
    parameters: parametersMap,
  };

  // remove all dashes, they are always in the same place and don't add value
  const shortenedTenancyId = tenancyId.replace(/-/g, '');
  // create sha256 hash of object
  // @ts-ignore objectHash supports sha256, but types are wrong
  const hashedObject: string = objectHash(objectToHash, { algorithm: 'sha256' });

  // hash consists of concatenation of tenancyId and object hash
  const hexString: string = shortenedTenancyId + hashedObject;

  // 5 hex chars are converted into 4 base32 chars
  // this makes the hash significantly shorter
  const hash = hexToBase32(hexString);

  return hash;
};
