import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import midTheme from '../Common/global/midTheme';
import Main from '../Common/components/Main/Main';
import { showDevTools, reloadBrowser } from '../lib/utils/tools';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import { useNavigationStore } from './context/Navigation/navigationStore';
import NavigationContext from './context/Navigation/Navigation.context';
import { NotificationsProvider } from '@adsk/alloy-react';
import DataContext from './context/Data/Data.context';
import { useStore } from './context/Data/dataStore';
import { ConfirmationModal } from '../Common/components/Modal/ConfirmationModal';
import ModalContext from '../Common/context/GlobalModal.context';
import { useModalStore } from '../Common/context/modalStore';
import ErrorBoundary from '../Common/components/ErrorBoundary/ErrorBoundary';
import { EnvironmentInfo } from '../Common/components/EnvironmentInfo/EnvironmentInfo';

const App: React.FC = (): JSX.Element => {
  const navigationStore = useNavigationStore();
  const dataStore = useStore();
  const modalStore = useModalStore();

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F12') {
        showDevTools();
      }

      if (event.key === 'F5') {
        reloadBrowser();
      }
    });
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  return (
    <ErrorBoundary handleResetAppState={handleResetAppState}>
      <ThemeProvider theme={midTheme}>
        <ModalContext.Provider value={modalStore}>
          <ConfirmationModal />
          <NotificationsProvider>
            <NavigationContext.Provider value={navigationStore}>
              <DataContext.Provider value={dataStore}>
                <Header />
                <Main>
                  <EnvironmentInfo hostname={window.location.host} />
                  <ScreenManager />
                </Main>
              </DataContext.Provider>
            </NavigationContext.Provider>
          </NotificationsProvider>
        </ModalContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
