import React, { useEffect, useState } from 'react';
import { TreeItem } from '../MIDTree/MIDTree.types';
import { useAsyncFetchDataWithArgs } from '../../global/hooks/http/hooks';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { ProjectFolder } from '../../../lib/interfaces/forge-api';
import { deleteProduct, getAllProducts } from '../../../lib/utils/products';
import { getProjectFolders } from '../../../lib/utils/workspace';
import text from '../../global/text/text.json';
import logger from '../../global/logger';

interface UseProductSelectionProps {
  selectedProjectId: string | undefined;
}
export interface UseProductSelectionState {
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement: TreeItem | undefined;
  products: DynamicContentProduct[] | null;
  handleDeleteProduct: (selectedProduct: DynamicContentProduct) => void;
  deleteProductLoading: boolean;
  productsLoading: boolean;
  productsError: Error | null;
  handleFolderClick: (selectedElement: TreeItem) => void;
  handleProductClick: (contentId?: string) => void;
  selectedProduct: DynamicContentProduct | undefined;
  setSelectedProduct: React.Dispatch<React.SetStateAction<DynamicContentProduct | undefined>>;
}

export const useProductSelection = ({
  selectedProjectId,
}: UseProductSelectionProps): UseProductSelectionState => {
  // Folders
  const [getRootProjectFoldersQueryArgs, setRootProjectFoldersQueryArgs] = useState<
    string[] | undefined
  >();
  const {
    data: rootProductFolders,
    loading: rootFoldersLoading,
    error: rootFoldersError,
  } = useAsyncFetchDataWithArgs<ProjectFolder[]>(getProjectFolders, getRootProjectFoldersQueryArgs);
  const [rootFoldersTreeItems, setRootFoldersTreeItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeElement, setSelectedFolderTreeElement] = useState<
    TreeItem | undefined
  >();

  // Products
  const [products, setProducts] = useState<DynamicContentProduct[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DynamicContentProduct | undefined>();
  const [productsQueryArgs, setProductsQueryArgs] = useState<string[] | undefined>();
  const {
    data: _fetchedProducts,
    loading: productsLoading,
    error: productsError,
  } = useAsyncFetchDataWithArgs<DynamicContentProduct[]>(getAllProducts, productsQueryArgs);
  const [deleteProductLoading, setDeleteProductLoading] = useState(false);

  useEffect(() => {
    if (_fetchedProducts) {
      setProducts(_fetchedProducts);
    }
  }, [_fetchedProducts]);

  useEffect(() => {
    // Reset data & view when projectId changes
    setRootProjectFoldersQueryArgs(selectedProjectId ? [selectedProjectId] : undefined);
    setProductsQueryArgs(selectedProjectId ? [selectedProjectId] : undefined);
    setSelectedProduct(undefined);
    setSelectedFolderTreeElement(undefined);
  }, [selectedProjectId, setSelectedProduct]);

  useEffect(() => {
    const foldersTreeItems: TreeItem[] =
      rootProductFolders?.map((folder) => ({
        id: folder.urn,
        label: folder.title,
        value: folder.urn,
        isExpandable: true,
        children: [],
      })) || [];
    setRootFoldersTreeItems(foldersTreeItems);
  }, [rootProductFolders]);

  const handleFolderClick = (item: TreeItem) => {
    setSelectedFolderTreeElement(item);
  };

  const handleProductClick = (contentId?: string) => {
    if (contentId) {
      const productClicked: DynamicContentProduct | undefined = products?.find(
        (product) => product.contentId === contentId,
      );
      if (productClicked) {
        setSelectedProduct(productClicked);
      }
    }
  };

  const handleDeleteProduct = async (selectedProduct: DynamicContentProduct) => {
    if (!selectedProduct?.contentId) {
      throw new Error(text.deleteProductErrorNoContentId);
    }

    try {
      setDeleteProductLoading(true);
      await deleteProduct(selectedProduct.tenancyId, selectedProduct.contentId);
      const filteredProducts = products?.filter(
        (product) => product.contentId !== selectedProduct.contentId,
      );
      setProducts(filteredProducts || null);
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : text.deleteTemplateFailMessage;
      logger.error(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setDeleteProductLoading(false);
    }
  };

  return {
    products,
    productsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleProductClick,
    handleDeleteProduct,
    deleteProductLoading,
    selectedProduct,
    setSelectedProduct,
  };
};

export default useProductSelection;
