import React from 'react';
import { Flyout, Icon } from '@adsk/alloy-react';
import { FlyoutContainer } from './styles';

export interface VersionInfoProps {
  IVTW_VERSION?: string;
  IVTA_VERSION?: string;
  RVTW_VERSION?: string;
  RVTA_VERSION?: string;
}

export const VersionInfo: React.FC<VersionInfoProps> = ({
  IVTW_VERSION,
  IVTA_VERSION,
  RVTW_VERSION,
  RVTA_VERSION,
}): JSX.Element => (
  <FlyoutContainer>
    <Flyout
      content={
        <div>
          {!IVTW_VERSION &&
            !IVTA_VERSION &&
            !RVTW_VERSION &&
            !RVTA_VERSION &&
            'Version Info Not Available'}
          {IVTW_VERSION && `${IVTW_VERSION}/`}
          {IVTA_VERSION && IVTA_VERSION}
          {RVTW_VERSION && `${RVTW_VERSION}/`}
          {RVTA_VERSION && RVTA_VERSION}
        </div>
      }
      style={{ width: '100%' }}
    >
      <Icon type={Icon.TYPES.QUESTION_CIRCLE} fr={0}></Icon>
    </Flyout>
  </FlyoutContainer>
);
